.newsletter {
    position: relative;
    width: 100%;
    padding: 75px 0;
    background-color: var(--gray-light-color);

    @media (--min-desktop) {
        padding: 120px 0;
    }

    .container {
        max-width: 500px;

        @media (--min-desktop) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            max-width: 1170px;
        }
    }
}

.newsletter-title {
    margin-bottom: 38px;
    font-size: 36px;

    @media (--min-desktop) {
        flex: 0 1 35%;
        margin-bottom: 0;
    }
}

.blog-newsletter--title {
    margin-bottom: 38px;
    font-size: 24px;
}

.newsletter-form {
    @media (--min-desktop) {
        flex: 0 1 65%;
    }

    .post-newsletter & {
        @media (--min-desktop) {
            flex: 1 1 100%;
        }
    }
}

.mc-form--parent {
    input[type="text"],
    input[type="email"] {
        border: none;
        border-bottom: 1px solid var(--black-color);
        background: transparent;
    }

    .newsletter-form & {
        @media (--min-desktop) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .post--newsletter & {
        @media (--min-desktop) {
            flex-direction: column;
        }
    }
}

.mc-form--email {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: .6px;

    label {
        flex: 0 0 auto;
    }

    input {
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
}

#mc_embed_signup .mc-form--email div.mce_inline_error {
    flex: 0 0 100%;
    padding: 0;
    font-size: 12px;
    font-family: var(--primary-font);
    font-weight: 300;
    color: var(--red-color);
    background: transparent;
}

.mc-field-group {
    border: none;
}

.mc-form--fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

.mc-form--checkbox {
    font-size: 12px;

    label {
        display: inline-block;
        margin-bottom: 2px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    input {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border: 1px solid var(--dark-color);

        &:checked {
            background-color: var(--dark-color);
        }
    }
}

.mc-form--submit {
    input {
        display: block;
        width: 100%;
        text-transform: uppercase;
        cursor: pointer;

        .blog-newsletter--form & {
            background: transparent;
            color: var(--dark-color);
            border: 1px solid var(--dark-color);

            &:hover {
                color: var(--light-color);
                background-color: var(--dark-color);
            }
        }
    }
}

.gdpr-mergeRow {
    font-size: 12px;

    label {
        display: inline-block;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 5px;
    }
}

.mc_fieldset {
    padding: 5px;
    margin: 10px 0;
    border: none;

    label {
        display: flex;
    }

    input {
        margin-right: 5px;
    }
}

.newsletter-form--label {
    font-size: 20px;
    margin-right: 10px;

    @media (--min-desktop) {
        margin-right: 20px;
    }
}

.form-privacy {
    font-size: 12px;
}

.newsletter-button {
    width: 100%;

    @media (--min-desktop) {
        flex: 0 1 15%;
    }
}

.mce-responses {
    margin-top: 10px;
}

#mce-error-response {
    color: var(--red-color);
}

#mce-success-response {
    color: var(--blue-color);
}
