.contact {
    margin-top: 50px;

    @media (--min-desktop) {
        display: flex;
        margin-top: 77px;
    }
}

.contact--item {
    position: relative;
}

.contact--column {
    @media (--min-desktop) {
        width: 50%;
    }

    &.is-right {
        background-color: var(--gray-light-color);
    }
}

.contact--map {
    width: 100%;
    height: 75vh;
    background-color: var(--gray-light-color);

    @media (--min-tablet) {
        height: 50vh;
    }
}

.contact--container {
    padding-top: 60px;
    padding-bottom: 50px;

    .contact--column.is-left & {
        padding-right: 40px;
        padding-left: 40px;
        margin-right: 0;
        margin-left: auto;

        @media (--min-desktop) {
            padding-right: 100px;
            padding-left: 20px;
        }
    }

    .contact--column.is-right & {
        margin-right: auto;
        margin-left: 0;

        @media (--min-desktop) {
            padding-right: 20px;
            padding-left: 100px;
        }
    }

    @media (--min-tablet) {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    @media (--min-desktop-xlarge) {
        max-width: 600px;
    }

    @media (--min-desktop-xxlarge) {
        max-width: 720px;
    }
}

.contact--title {
    position: relative;
    margin-bottom: 55px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: 1.6px;

    @media (--min-tablet) {
        font-size: 32px;
        line-height: .75;
        letter-spacing: 1.8px;
    }

    .contact--column.is-form & {
        text-align: center;
    }
}

.contact--subtitle {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 2px;
}

.contact--text,
.contact--link {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .4px;
}

.contact--text a {
    position: relative;
    color: var(--dark-color);

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0;
        height: 1px;
        background-color: var(--dark-color);
        transition: width var(--main-transition);
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

.contact--link {
    position: relative;
    display: inline-block;
    margin-bottom: 35px;
    color: var(--dark-color);
    cursor: hand;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--dark-color);
    }

    svg {
        position: absolute;
        top: 2px;
        right: -20px;
        display: block;
        opacity: 0;
        font-size: 15px;
        color: var(--dark-color);
        transition: var(--main-transition);
    }

    &:hover:before {
        animation: underlineHover 1s var(--main-easing);
    }

    &.is-active {
        color: var(--gray-medium-color);

        &:before {
            content: none;
        }

        svg {
            opacity: 1;
        }
    }
}
