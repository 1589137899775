.related-posts {
    position: relative;
    width: 100%;
    padding-top: 50px;
}

.related-posts--title {
    max-width: 90%;
    margin: 0 auto 50px;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
}

.related-posts--list {
    @media (--min-desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.related-post {
    display: block;
    width: 90%;
    margin: 0 auto;
    color: var(--dark-color);

    @media (--min-tablet) {
        margin-bottom: 75px;
    }

    @media (--min-desktop) {
        flex: 0 1 calc(33.33% - 100px);
        margin: 75px 0 120px;

        &:nth-child(2) {
            flex: 0 1 calc(28% - 100px);
            margin-top: 0;
        }
    }

    @media (--panoramic-laptop) {
        flex: 0 1 20%;

        &:nth-child(2) {
            flex: 0 1 22%;
        }
    }
}

.related-post--media {
    position: relative;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform .8s var(--main-easing);

    .related-post:nth-child(2) & {
        @media (--min-desktop) {
            height: 275px;
        }
    }
}

.related-post--category {
    position: absolute;
    top: 90px;
    right: -112px;
    width: 200px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transform: rotate(-90deg);

    .related-post:nth-child(2) & {
        @media (--min-desktop) {
            top: 128px;
            right: -150px;
            width: 275px;
        }
    }
}

.related-post--content {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media (--min-tablet) {
        margin-bottom: 0;
    }
}

.related-post--info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px;
    letter-spacing: 1.8px;
}

.related-post--name {
    @media (--min-desktop-xxlarge) {
        display: flex;
        justify-content: space-between;
    }
}

.related-post--title {
    margin-bottom: 20px;
    font-size: 28px;
    letter-spacing: .6px;
    line-height: 1.13;

    @media (--min-tablet) {
        margin-bottom: 0;
    }

    @media (--min-desktop-xxlarge) {
        margin-bottom: 0;
    }
}
