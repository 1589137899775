.professional--grid {
    padding: 50px 0;

    @media (--min-desktop) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -48px;
    }

    @media (--min-desktop-xlarge) {
        margin-bottom: 100px;
    }
}

.professional--post {
    display: block;
    width: 90%;
    margin: 0 auto;
    color: var(--dark-color);

    @media (--min-desktop) {
        flex: 0 1 calc(45% - 48px);
        margin: 0 48px 50px;
    }

    @media (--min-desktop-xlarge) {
        flex: 0 1 calc(33.33% - 96px);
        margin: 0 48px 50px;
    }
}

.professional-post--media {
    position: relative;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform .8s var(--main-easing);

    .professional--post:hover & {
        transform: scale(.95);
    }
}

.professional-post--category {
    position: absolute;
    top: 90px;
    right: -112px;
    width: 200px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transform: rotate(-90deg);
}

.professional-post--content {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    margin-bottom: 40px;
}

.professional-post--name {
    @media (--min-desktop-xxlarge) {
        display: flex;
        justify-content: space-between;
    }
}

.professional-post--title {
    margin-bottom: 20px;
    font-size: 28px;
    letter-spacing: .6px;
    line-height: 1.13;

    @media (--min-tablet) {
        margin-bottom: 0;
    }

    @media (--min-desktop-xxlarge) {
        margin-bottom: 0;
    }
}
