.product-contact {
    padding-top: 45px;
    padding-bottom: 60px;
    background-color: var(--gray-light-color);

    @media (--min-tablet) {
        padding-top: 70px;
        padding-bottom: 65px;
    }
}

.product-contact--title {
    margin-bottom: 35px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: .6px;
    text-align: center;

    @media (--min-tablet) {
        margin-bottom: 45px;
        font-size: 32px;
        line-height: .75;
        letter-spacing: 1.8px;
    }
}

.product-contact--form {
    max-width: 665px;
    margin: 0 auto;
}

.product-contact--submit {
    width: 100%;
    border: 0;
    cursor: pointer;

    @media (--min-tablet) {
        width: auto;
    }
}
