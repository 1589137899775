.about-form {
    position: relative;
    width: 100%;
    padding-bottom: 60px;

    @media (--min-desktop) {
        padding: 120px 0;
    }

    .container {
        @media (--min-desktop) {
            display: flex;
            justify-content: space-between;
        }
    }
}

.about-form--intro {
    @media (--min-desktop) {
        flex: 0 1 50%;
        padding-right: 170px;
    }
}

.about-form--title {
    max-width: 90%;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: .6px;

    @media (--min-desktop) {
        font-size: 36px;
        letter-spacing: .8px;
    }
}

.about-form--text {
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: .5px;

    @media (--min-desktop) {
        font-size: 18px;
    }
}

.about-form--content {
    @apply --load-animation;

    @media (--min-desktop) {
        flex: 0 1 50%;
    }
}
