.about-cta {
    position: relative;
    width: 100%;
    padding: 65px 0 100px;

    @media (--min-desktop) {
        padding: 120px 0;
    }

    .container {
        position: relative;

        @media (--min-desktop) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.about-cta--background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background-image: linear-gradient(var(--light-color), transparent);

        @media (--min-desktop) {
            width: 30%;
            height: 100%;
            background-image: linear-gradient(to right, var(--light-color), transparent);
        }
    }
}

.about-cta--title {
    margin-bottom: 50px;
    font-size: 28px;
    text-align: center;

    @media (--min-desktop) {
        max-width: 320px;
        margin-bottom: 0;
        text-align: left;
    }
}

.about-cta--button {
    display: block;
    margin: 0 auto;
    text-transform: uppercase;

    @media (--min-desktop) {
        margin: 0;
    }
}
