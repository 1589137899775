.about-certification--pretitle {
    position: relative;
    padding: 10px 0;
    margin-bottom: 30px;
    font-size: 14px;
    letter-spacing: .5px;
    text-transform: uppercase;

    @media (--min-desktop) {
        padding: 20px 0;
        font-size: 16px;
        letter-spacing: 1.8px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        width: calc(100% + 40px);
        height: 1px;
        background: var(--disabled-color);
    }

    &:after {
        content: '';
        position: absolute;
        right: -20px;
        bottom: 0;
        width: calc(100% + 40px);
        height: 1px;
        background: var(--disabled-color);
    }
}

.about-certification--icon {
    margin-bottom: 20px;
}

.about-certification--item {
    position: relative;
}

.about-certification--title {
    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: .5px;
    line-height: 1.17;
}

.about-certification--text {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: .5px;

    &:after {
        content: '';
        position: absolute;
        right: -20px;
        bottom: 0;
        width: calc(100% + 40px);
        height: 1px;
        background: var(--disabled-color);
    }

    &:last-child {
        padding-bottom: 0;

        &:after {
            content: none;
        }
    }
}
