.about-features {
    position: relative;
    width: 100%;

    @media (--min-desktop) {
        display: flex;

        &.is-reverted {
            flex-direction: row-reverse;
        }
    }
}

.about-features--media {
    position: relative;

    @media (--min-desktop) {
        flex: 0 1 50%;
        margin-bottom: 0;
    }
}

.about-features--img {
    width: 100%;
    height: 370px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        height: 516px;
    }
}

.about-features--content {
    padding: 60px 0;
    background-color: var(--gray-light-color);

    @media (--min-desktop) {
        flex: 0 1 50%;
        padding-top: 150px;
    }

    .container {
        @apply --load-animation;

        display: flex;
        flex-wrap: wrap;
        max-width: 350px;
    }
}

.about-features--pretitle,
.about-features--title,
.about-features--text {
    flex: 0 0 100%;
    padding-right: 25px;
    padding-left: 25px;

    @media (--min-tablet) {
        padding: 0;
    }
}

.about-features--pretitle {
    margin-bottom: 10px;
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media (--min-tablet) {
        font-size: 16px;
    }
}

.about-features--title {
    font-family: var(--primary-font);
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: .6px;

    @media (--min-tablet) {
        flex: 0 1 330px;
        margin-right: 40px;
        font-size: 36px;
        line-height: 1.1;
        letter-spacing: .8px;
    }
}

.about-features--text {
    margin-top: 10px;
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;

    @media (--min-tablet) {
        flex: 0 1 350px;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: .6px;
    }
}
