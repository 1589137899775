.catalog-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    width: 100%;
    height: 100%;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--dark-color);
        opacity: .7;
    }

    &.is-popup-visible {
        display: block;
    }
}

.catalog-popup--content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    transform: translate(-50%, -50%);

    @media (--min-desktop) {
        width: 50%;
    }

    @media (--panoramic-laptop) {
        width: 50%;
        height: 50%;
    }
}

.catalog-popup--container {
    position: relative;
    display: flex;
}

.catalog-popup--left {
    flex: 0 1 50%;
    width: 390px;
    height: 390px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.catalog-popup--right {
    position: relative;
    flex: 0 1 50%;
    padding: 30px 30px 40px 40px;
    background-color: var(--light-color);
}

.catalog-popup--title {
    max-width: 300px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: .6px;
}

.catalog-popup--info {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: .5px;
}

.catalog-popup--button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.catalog-popup--close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: var(--dark-color);
}
