.home-tabs {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 540px;
    overflow: hidden;

    @media (--min-desktop) {
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
    }

    @media (--panoramic-laptop) {
        height: 540px;
    }
}

.home-tabs--list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.home-tabs--list-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: 1s var(--secondary-easing);

    &.is-selected {
        z-index: 1;
        opacity: 1;
    }
}

.home-tabs--header {
    position: relative;
    padding: 20px 0;
    margin-top: 0;
    margin-bottom: auto;
    z-index: 1;
    color: var(--light-color);
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    text-align: center;

    @media (--min-desktop) {
        flex: 0 0 100%;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid var(--light-color);
        opacity: .3;
    }
}

.home-tabs--line {
    position: relative;
    padding: 0 50px 20px;
    z-index: 1;

    @media (--min-desktop) {
        display: flex;
        align-items: center;
        flex: 0 1 50%;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 120px;
    }

    @media (--min-desktop-large) {
        padding-left: 180px;
    }

    @media (--min-desktop-xlarge) {
        padding-left: 250px;
    }

    @media (--min-desktop-xxlarge) {
        padding-left: 320px;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 30%;
    }

    svg {
        display: block;
        font-size: 21px;
        color: var(--light-color);
    }

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 150px;
        margin-left: 10px;
        border-left: 1px solid var(--light-color);
        transform: scale(1, 1);
        transform-origin: 0 0;

        @media (--min-desktop) {
            width: 100%;
            height: 0;
            margin-left: 0;
            border-left: 0;
            border-bottom: 1px solid var(--light-color);
        }

        .changing & {
            transform: scale(1, 1);
            animation: lineDown .35s var(--main-easing) forwards;

            @media (--min-desktop) {
                animation: lineRight .35s var(--main-easing) forwards;
            }
        }
    }
}

.home-tabs--content {
    position: relative;
    z-index: 1;

    @media (--min-desktop) {
        flex: 0 1 45%;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 40%;
    }
}

.home-tabs--content-item {
    height: 0;
    padding: 0 45px;
    color: var(--light-color);
    overflow: auto;

    @media (--min-desktop) {
        display: flex;
        align-items: center;
        padding-right: 120px;
        padding-left: 40px;
    }

    @media (--min-desktop-large) {
        padding-right: 180px;
    }

    @media (--min-desktop-xlarge) {
        padding-right: 250px;
    }

    @media (--min-desktop-xxlarge) {
        padding-right: 320px;
    }

    &.is-selected {
        height: 100%;
    }
}

.home-tabs--content-icon {
    display: block;
    margin-bottom: 10px;
    font-size: 30px;
    opacity: 0;
    transition: none;

    @media (--min-desktop) {
        margin-right: 30px;
        margin-bottom: 0;
        font-size: 40px;
    }

    svg {
        display: block;
    }

    .is-selected & {
        opacity: 1;
        transition: .35s var(--main-easing) .25s;
    }
}

.home-tabs--content-text {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: .5px;
    opacity: 0;
    transform: translate(-20px, 0);
    transition: none;

    @media (--min-desktop) {
        font-size: 18px;
    }

    .is-selected & {
        opacity: 1;
        transform: translateX(0);
        transition: .35s var(--main-easing) .3s;
    }
}

.home-tabs--links {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 0;
    padding: 25px 20px;
    z-index: 1;

    @media (--min-desktop) {
        width: 100%;
        padding-right: 120px;
        padding-left: 120px;
    }

    @media (--min-desktop-large) {
        padding-right: 180px;
        padding-left: 180px;
    }

    @media (--min-desktop-xlarge) {
        padding-right: 250px;
        padding-left: 250px;
    }

    @media (--min-desktop-xxlarge) {
        padding-right: 320px;
        padding-left: 320px;
    }
}

.home-tabs--nav {
    width: 100%;

    @media (--min-desktop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.home-tabs--nav-item {
    display: none;
    color: var(--light-color);
    font-size: 28px;
    line-height: 1.15;
    letter-spacing: .6px;
    text-align: center;
    cursor: pointer;

    @media (--min-desktop) {
        display: block;
        opacity: .5;
    }

    &.is-selected {
        display: block;

        @media (--min-desktop) {
            opacity: 1;
        }
    }
}

.home-tabs--links-previous,
.home-tabs--links-next {
    display: block;
    color: var(--light-color);
    font-size: 16px;

    @media (--min-desktop) {
        display: none;
    }

    svg {
        display: block;
    }
}
