.info-window {
    position: absolute;
    left: 50%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 300px;
    padding-top: 50px;
    margin: 0 auto;
    background-image: url(/img/info-window-mask-green.svg);
    background-repeat: no-repeat;
    background-size: 500px 500px;
    background-position: -40px -70px;
    box-shadow: 0 10px 25px color(var(--black-color) a(50%));
    transform: translateX(-50%);
    pointer-events: auto;

    @media (--min-desktop) {
        right: 0;
        bottom: auto;
        left: auto;
        top: 50%;
        padding-top: 50px;
        padding-bottom: 20px;
        justify-content: flex-end;
        width: auto;
        min-width: 62px;
        max-width: none;
        transform: translateY(-50%);
        background-size: auto 600px;
        background-position: -310px -30px;
    }

    @media (--min-desktop-large) {
        padding-top: 80px;
        padding-bottom: 50px;
    }

    &.is-visible {
        background-size: 500px 500px;
        background-position: -40px -80px;
        background-color: transparent;
        transition: none;

        @media (--min-desktop) {
            background-size: auto 600px;
            background-position: -325px -30px;
        }
    }

    &.is-green {
        background-image: url(/img/info-window-mask-green.svg);
    }

    &.is-yellow {
        background-image: url(/img/info-window-mask-yellow.svg);
    }

    &.is-purple {
        background-image: url(/img/info-window-mask-purple.svg);
    }

    &.is-blue {
        background-image: url(/img/info-window-mask-blue.svg);
    }

    &.is-red {
        background-image: url(/img/info-window-mask-red.svg);
    }

    &.is-orange {
        background-image: url(/img/info-window-mask-orange.svg);
    }

    &.is-dark-blue {
        background-image: url(/img/info-window-mask-dark-blue.svg);
    }

    &.is-dark-red {
        background-image: url(/img/info-window-mask-dark-red.svg);
    }
}

.info-window--toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 50px;

    @media (--min-desktop) {
        right: 0;
        left: auto;
        width: 62px;
        height: 100%;
        padding: 20px;
    }

    .is-visible & {
        @media (--min-desktop) {
            right: auto;
            left: 0;
        }
    }
}

.info-window--close,
.info-window--open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    color: var(--light-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.info-window--close {
    opacity: 0;
    transition: none;

    .is-visible & {
        opacity: 1;
        transition: opacity .35s var(--main-easing) .35s;

        @media (--min-desktop) {
            align-items: center;
        }
    }

    svg {
        display: block;
        margin-left: auto;

        @media (--min-desktop) {
            margin: 0;
            transform: rotate(-90deg);
        }
    }
}

.info-window--open {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity .35s var(--main-easing) .35s;

    @media (--min-desktop) {
        flex-direction: column;
    }

    .is-visible & {
        opacity: 0;
        transition: none;
    }

    svg {
        @media (--min-desktop) {
            transform: rotate(-90deg);
        }
    }

    span {
        display: block;
        padding: 0 20px;
        letter-spacing: 3.5px;

        @media (--min-desktop) {
            padding: 10px 0;
            transform: rotate(180deg);
            writing-mode: vertical-rl;
        }
    }
}

.info-window--content {
    position: relative;
    z-index: 1;
    max-height: 0;
    padding-left: 25px;
    color: var(--light-color);
    transition: max-height .35s var(--main-easing);
    overflow: hidden;

    @media (--min-desktop) {
        width: 0;
        height: auto;
        max-height: none;
        padding-top: 150px;
        flex: 1 1 auto;
        padding-left: 50px;
        transition: width .35s var(--main-easing);
    }

    .is-visible & {
        max-height: 280px;

        @media (--min-desktop) {
            max-width: 40vw;
            width: 450px;
            max-height: none;
        }
    }
}

.info-window--category {
    position: relative;
    width: 65%;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.8;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-20px);
    transition: none;

    @media (--min-desktop) {
        width: 100%;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        border-bottom: 1px solid var(--light-color);
    }

    .is-visible & {
        opacity: 1;
        transform: translate(0, 0);
        transition: .35s var(--main-easing) .1s;

        &:before {
            width: 15px;
            transition: .35s var(--main-easing) .35s;
        }
    }
}

.info-window--title {
    width: 65%;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 1;
    letter-spacing: 1px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-20px);
    transition: none;

    @media (--min-desktop) {
        width: 100%;
        font-size: 30px;
        letter-spacing: 1.2px;
    }

    .is-visible & {
        opacity: 1;
        transform: translate(0, 0);
        transition: .35s var(--main-easing) .2s;
    }

    sup {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.57;
        letter-spacing: 1.5px;
        color: var(--light-color);
    }
}

.info-window--details {
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 1.4;
    opacity: 0;
    transform: translateX(-20px);
    transition: none;

    .is-visible & {
        opacity: 1;
        transform: translate(0, 0);
        transition: .35s var(--main-easing) .3s;
    }
}

.info-window--vars {
    padding-top: 30px;
}

.info-window--vars-item,
.info-window--related-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateX(-5px);
    transition: none;

    @media (--min-desktop) {
        flex-flow: row nowrap;
        height: 20px;
        overflow: hidden;
    }

    .is-visible & {
        height: auto;
        opacity: 1;
        transform: translate(0, 0);
        transition: .35s var(--main-easing) .3s;

        & + .info-window--vars-item {
            transition-delay: .35s;
        }
    }
}

.info-window--vars-image,
.info-window--related-image {
    flex: 0 1 30px;
    width: 30px;
    height: 20px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.info-window--vars-title,
.info-window--related-title {
    flex: 0 1 calc(100% - 45px);
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: .5px;

    @media (--min-desktop) {
        flex: 0 1 auto;
        white-space: nowrap;
    }
}

.info-window--related-cta {
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: .4px;
    text-transform: lowercase;
    color: inherit;
    text-decoration: underline;

    @media (--min-desktop) {
        flex: 1 1 auto;
        padding: 0 20px;
    }
}

.info-window--related-intro {
    padding: 5px 0 10px;
    font-size: 12px;
    letter-spacing: .4px;
    opacity: 0;
    transform: translateX(-5px);
    transition: none;

    .is-visible & {
        opacity: 1;
        transform: translate(0, 0);
        transition: .35s var(--main-easing) .35s;
    }

    @media (--min-desktop) {
        white-space: nowrap;
    }
}

.info-window--cta {
    position: relative;
    width: 0;
    height: 0;
    z-index: 3;
    opacity: 0;
    overflow: hidden;
    transition: none;

    @media (--min-desktop) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        transition: none;
    }

    .is-visible & {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: height .35s var(--main-easing), opacity .35s var(--main-easing) .4s;

        @media (--min-desktop) {
            transition: opacity .35s var(--main-easing) .4s;
        }
    }

    a {
        display: block;
        white-space: nowrap;
    }
}
