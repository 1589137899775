.related-products {
    position: relative;
    width: 100%;
    padding: 36px 0 60px;

    @media (--min-desktop) {
        padding: 90px 0 125px;
    }
}

.related-products--title {
    max-width: 195px;
    margin: 0 auto 44px;
    font-size: 28px;
    line-height: 1.14;
    text-align: center;
    text-transform: uppercase;

    @media (--min-desktop) {
        max-width: none;
        margin-bottom: 95px;
        font-size: 32px;
    }
}

.related-products--list {
    @media (--min-tablet) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 -15px;
    }
}

.related-products--item {
    position: relative;
    display: block;
    height: 180px;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: .4s all;

    @media (--min-tablet) {
        flex: 0 1 calc(33.33% - 30px);
        height: 240px;
        margin: 0 15px;
    }

    &:hover {
        filter: grayscale(100%);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: color(var(--black-color) a(.15));
    }
}

.related-products--item-name {
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 24px;
    color: var(--light-color);
    opacity: 1;
    transition: .4s all .3s;

    @media (--min-desktop) {
        left: 44px;
    }

    .related-products--item:hover & {
        opacity: 0;
        transition: .4s all;
    }
}

.related-products--item-link {
    opacity: 0;
    position: absolute;
    bottom: 40px;
    left: 44px;
    font-size: 32px;
    color: var(--light-color);
    letter-spacing: 1.8px;
    transition: .4s all;

    svg {
        font-size: 20px;
        vertical-align: middle;
    }

    .related-products--item:hover & {
        opacity: 1;
        transition: .4s all .3s;
    }
}

.related-products--item-new {
    position: absolute;
    top: 0;
    left: 32px;
    width: 49px;
    height: 25px;
    padding-top: 5px;
    font-size: 13px;
    color: var(--light-color);
    text-align: center;
    background: var(--green-color);
    box-shadow: 0 3px 3px 0 color(var(--dark-color) a(.3));

    @media (--min-desktop) {
        left: 44px;
        width: 65px;
        height: 33px;
        font-size: 18px;
    }
}
