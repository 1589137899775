input,
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    box-shadow: none;
}

.form--group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border: none;

    @media (--min-desktop) {
        flex-direction: row;

        &.is-right {
            flex-direction: row-reverse;
        }
    }
}

.form--input,
.login-form--group-input {
    position: relative;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;
    border: 1px solid var(--dark-color);
    border-radius: 0;
    background: transparent;

    @media (--min-desktop) {
        flex: 1 1 100%;
    }

    label {
        color: var(--dark-color);
    }

    .contact & {
        border: 1px solid var(--dark-color);

        &:not(:first-child) {
            border-top: none;

            @media (--min-desktop) {
                border-top: 1px solid var(--dark-color);
                border-left: none;
            }
        }
    }

    .login-popup & {
        border-bottom: 1px solid var(--dark-color);
    }

    .login & {
        margin-bottom: 20px;

        @media (--min-tablet) {
            width: 200px;
        }
    }

    .about-form--content & {
        border: 1px solid var(--dark-color);

        &:not(:first-child) {
            @media (--max-tablet) {
                border-top: none;
            }
        }

        @media (--min-desktop) {
            &:not(:first-child) {
                border-left: none;
            }
        }
    }

    & + .form--input,
    & + .form--select {
        border-top: 0 solid transparent;

        @media (--min-desktop) {
            border-top: 1px solid var(--dark-color);
            border-left: 0 solid transparent;
        }
    }
}

.login-form--group {
    display: flex;
    flex-direction: column;
}

.form--select {
    position: relative;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;
    border: 1px solid var(--dark-color);
    background-color: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 100 100' width='5px' height='5px'><polygon fill='#000' points='50 63.104 13.966 27.069 7.415 33.62 50 76.207 92.585 33.62 86.034 27.069 50 63.104'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    border-radius: 0;

    @media (--min-desktop) {
        flex: 1 1 100%;
    }

    .about-form--content & {
        &:not(:last-child) {
            @media (--max-tablet) {
                border-bottom: none;
            }
        }

        @media (--min-desktop) {
            &:not(:first-child) {
                border-left: none;
            }
        }
    }

    &:required:invalid {
        color: color(var(--dark-color) a(70%));
    }

    option[value=""][disabled] {
        display: none;
    }

    & + .form--select,
    & + .form--input {
        border-top: 0 solid transparent;

        @media (--min-desktop) {
            border-top: 1px solid var(--dark-color);
            border-left: 0 solid transparent;
        }
    }
}

.form--textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;
    background-color: transparent;
    border: 1px solid var(--dark-color);
    resize: none;
}

.form--checkbox {
    position: relative;
    display: flex;
    flex: 0 0 100%;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.65;
    letter-spacing: 1px;
    cursor: hand;
    cursor: pointer;

    &.is-disabled {
        color: var(--disabled-color);
        cursor: not-allowed;

        span {
            border-color: var(--disabled-color);
            cursor: not-allowed;
        }

        input {
            cursor: not-allowed;
        }
    }

    input {
        width: 1px;
    }

    a {
        text-decoration: underline;
        color: var(--dark-color);
        transition: var(--main-transition);

        &:hover {
            color: var(--gray-medium-color);
        }
    }

    span {
        display: inline-block;
        width: 14px;
        height: 12px;
        margin-top: 2px;
        margin-right: 10px;
        border: solid 1px var(--dark-color);
    }

    input:checked + span {
        background-color: var(--dark-color);
    }
}

input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;
    opacity: 0;
    cursor: pointer;
}

.form-file--label:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    pointer-events: none;
    background-color: transparent;
    background-image: url('/img/Clip.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.form--label {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: .4px;

    .login-popup--form & {
        font-size: 14px;
    }

    a {
        color: var(--dark-color);
        text-decoration: underline;
    }
}

.form--error {
    position: absolute;
    bottom: -18px;
    left: 0;
    font-size: 11px;
    color: var(--dark-red-color);
}

input[type="submit"] {
    text-transform: uppercase;
}
