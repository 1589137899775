.page-head {
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 45px;
    background-color: var(--gray-light-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-tablet) {
        padding-top: 40px;
        padding-bottom: 95px;
        margin-top: 55px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .download-list & {
        color: var(--light-color);
    }

    .is-logged & {
        margin-top: 0;
    }
}

.page-head--breadcrumbs {
    flex: 0 0 100%;
    font-family: var(--primary-font);
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: .4px;

    @media (--min-tablet) {
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: .4px;
    }

    a {
        color: var(--gray-medium-color);
        text-decoration: none;
    }

    i {
        margin-left: 5px;
        font-size: 5px;
        font-style: normal;
    }
}

.page-head--pretitle,
.page-head--title,
.page-head--text {
    flex: 0 0 100%;
    padding-right: 25px;
    padding-left: 25px;

    @media (--min-tablet) {
        padding: 0;
    }
}

.page-head--pretitle {
    margin-top: 40px;
    margin-bottom: 10px;
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media (--min-tablet) {
        margin-top: 80px;
        font-size: 16px;
    }
}

.page-head--title {
    font-family: var(--primary-font);
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: .6px;

    @media (--min-tablet) {
        flex: 0 1 330px;
        margin-right: 40px;
        font-size: 36px;
        line-height: 1.1;
        letter-spacing: .8px;
    }
}

.page-head--text {
    margin-top: 10px;
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;

    @media (--min-tablet) {
        flex: 0 1 400px;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: .6px;
    }
}

.textoInferior {
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: .6px;

    h1 {
        font-size: 36px;
        line-height: 1.1;
        letter-spacing: .8px;
        font-weight: normal;
        margin-bottom: 10px;
        display: block;
    }

    h2 {
        font-size: 30px;
        line-height: 1.1;
        letter-spacing: .8px;
        font-weight: normal;
        margin-bottom: 10px;
        margin-top: 20px;
        display: block;
    }

    h3 {
        font-size: 22px;
        line-height: 1.1;
        letter-spacing: .8px;
        font-weight: normal;
        margin-bottom: 10px;
        display: block;
    }

    p {
        margin-bottom: 20px;
    }

    .page-head--text {
        flex: 0 1 600px;
    }

    .show-more {
        cursor: pointer;
    }
}

.read-more .summary-remainder {
    display: none;
}

.read-more.expanded .summary-ellipsis {
    display: none;
}

.read-more.expanded .summary-remainder {
    display: block;
}

.d-none {
    display: none;
}
