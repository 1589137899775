.social {
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (--min-tablet) {
        padding: 80px 0 100px;
    }

    .container {
        @media (--min-desktop-large) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            justify-content: space-around;
            max-width: 1170px;
        }
    }
}

.social-intro {
    padding: 100px 0;

    @media (--panoramic-laptop) {
        flex: 0 1 30%;
    }
}

.social-pretitle {
    margin-bottom: 10px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
}

.social-title {
    max-width: 210px;
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 1.14;

    @media (--min-desktop) {
        max-width: 270px;
        margin-bottom: 40px;
        font-size: 36px;
    }
}

.social-icons {
    font-size: 22px;
}

.social-icon--link {
    margin-right: 20px;
    color: var(--dark-color);
}

.social-content {
    @media (--min-tablet) {
        display: flex;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 70%;
    }
}

.highlighted--social-item {
    padding: 11px;
    margin-bottom: 20px;
    box-shadow: 0 10px 25px 0 color(var(--black-color) a(.25));

    @media (--min-tablet) {
        display: flex;
        flex-direction: column;
        max-width: 395px;
        margin-right: 14px;
        margin-bottom: 12px;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 50%;
    }
}

.highlighted-item--img {
    width: 100%;

    @media (--panoramic-laptop) {
        max-height: 280px;
    }
}

.highlighted-item--description {
    padding: 14px 10px;
    font-size: 14px;
    line-height: 1.43;

    @media (--min-desktop) {
        max-width: 90%;
        font-size: 18px;
    }
}

.highlighted-item--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px;
}

.highlighted-item--likes {
    color: var(--gray-medium-color);
    letter-spacing: .4px;

    @media (--min-desktop) {
        font-size: 18px;
    }

    svg {
        vertical-align: middle;
    }
}

.highlighted-item--share {
    display: flex;

    span {
        display: none;
        margin-right: 10px;
        color: var(--dark-color);

        @media (--min-desktop) {
            display: block;
        }
    }

    svg {
        font-size: 18px;
        color: var(--dark-color);
        vertical-align: middle;
    }
}

.social-item--list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 140px;

    @media (--min-tablet) {
        margin: 0;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 50%;
    }
}

.social-item {
    display: flex;
    align-items: flex-end;
    flex: 0 1 44%;
    flex: 0 1 calc(50% - 16px);
    height: 136px;
    margin: 12px 8px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (--min-tablet) {
        height: 213px;
        margin: 0 8px 12px;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 44%;
    }
}

.social-item--footer {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 0 6px 6px;
    color: var(--light-color);

    svg {
        vertical-align: middle;
    }
}

.social-item--likes {
    letter-spacing: .4px;
}

.social-item--share {
    color: var(--light-color);
}

.social-image--container {
    position: relative;
    display: none;
    flex: 0 1 70%;

    @media (--min-tablet) {
        display: block;
    }
}

.social--image {
    position: absolute;
    max-width: 440px;

    &:last-child {
        top: -800px;
        left: 300px;

        @media (--min-desktop-large) {
            top: -344px;
            right: 0;
        }

        @media (--min-desktop-xlarge) {
            top: -450px;
        }
    }

    &:first-child {
        right: 0;
        bottom: -555px;

        @media (--min-desktop-large) {
            left: 0;
            right: auto;
        }

        @media (--min-desktop-xlarge) {
            bottom: -355px;
        }
    }
}
