.professional--container {
    .container {
        @media (--min-desktop) {
            display: flex;
        }
    }
}

.professional--content {
    @media (--min-desktop) {
        flex: 0 1 calc(70% - 50px);
        margin-right: 50px;
    }

    h1,
    h2,
    h3 {
        margin-bottom: 35px;
        font-size: 20px;
        line-height: 1.5;

        @media (--min-desktop) {
            font-size: 24px;
        }
    }

    p {
        position: relative;
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 1.43;

        @media (--min-desktop) {
            margin-bottom: 60px;
            font-size: 18px;
        }

        &:last-child {
            padding-bottom: 50px;
            border-bottom: 1px solid var(--dark-color);

            @media (--min-desktop) {
                margin-bottom: 100px;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 20px;
                left: 0;
                width: 100%;
                height: 1px;
                background: var(--dark-color);
            }
        }
    }

    a {
        color: var(--dark-color);
        text-decoration: underline;
    }

    blockquote {
        position: relative;
        margin-bottom: 50px;
        font-size: 24px;
        line-height: 1.3;

        @media (--min-desktop) {
            max-width: 580px;
            margin: 0 auto 80px;
            font-size: 28px;
        }

        &:before {
            content: '“';
            position: absolute;
            top: -20px;
            left: -10px;
            font-size: 32px;
        }

        &:after {
            content: '”';
            position: absolute;
            bottom: -20px;
            right: 0;
            font-size: 32px;
        }
    }

    img {
        width: 100%;
        margin-bottom: 40px;

        @media (--min-desktop) {
            margin-bottom: 60px;
        }
    }

    &:first-letter {
        font-size: 72px;
        font-weight: 100;
        line-height: 1;
    }
}

.professional--aside {
    @media (--min-desktop) {
        flex: 0 1 calc(30% - 50px);
        margin-left: 50px;
    }
}

.professional--newsletter {
    width: 90%;
    margin: 0 auto 50px;
    padding: 40px 20px;
    background: var(--gray-light-color);

    @media (--min-desktop) {
        width: 100%;
    }
}

.professional--collection-cta {
    display: block;
    width: calc(100% + 40px);
    margin-bottom: 90px;
    margin-left: -20px;
    overflow: hidden;

    @media (--min-desktop) {
        width: 100%;
        margin-left: 0;
    }

    &:hover {
        img {
            transform: scale(.9);
        }
    }

    img {
        transform: scale(1);
        transition: transform .8s var(--main-easing);
    }
}

.professional--collection {
    padding-right: 10px;
    color: var(--dark-color);
    letter-spacing: 1.8px;
    text-align: right;
}
