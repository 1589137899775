.about-header {
    position: relative;
    width: 100%;
    margin-top: 50px;

    @media (--min-desktop) {
        margin-top: 75px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
    }
}

.about-header--background {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        width: 80%;
        height: 100%;
    }

    .is-certification & {
        height: 80%;
    }
}

.about-header--content {
    max-width: 350px;
    padding-top: 200px;
    margin-bottom: 80px;

    @media (--min-desktop) {
        padding-top: 0;
    }
}

.about-header--pretitle,
.about-header--title,
.about-header--text {
    flex: 0 0 100%;
    padding-right: 25px;
    padding-left: 25px;

    @media (--min-tablet) {
        padding: 0;
    }
}

.about-header--pretitle {
    margin-top: 40px;
    margin-bottom: 10px;
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media (--min-tablet) {
        margin-top: 80px;
        font-size: 16px;
    }
}

.about-header--title {
    font-family: var(--primary-font);
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: .6px;

    @media (--min-tablet) {
        flex: 0 1 330px;
        margin-right: 40px;
        font-size: 36px;
        line-height: 1.1;
        letter-spacing: .8px;
    }
}

.about-header--text {
    margin-top: 10px;
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;

    @media (--min-tablet) {
        flex: 0 1 350px;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: .6px;
    }
}
