input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.container {
    @apply --container;
}

html {
    width: 100%;
}

body {
    width: 100%;
    color: var(--dark-color);
    font-family: var(--primary-font);
    font-weight: 300;
    font-style: normal;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

[v-cloak] {
    display: none;
}
