.products {
    @media (--min-desktop) {
        display: flex;
    }
}

.products--grid {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: flex-start;
    padding: 30px;

    @media (--min-desktop-xlarge) {
        padding: 60px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 6;
        width: 100%;
        height: 100%;
        background-color: var(--gray-light-color);
        transform-origin: right 50%;
        transform: scale3d(0, 1, 1);
        transition: none;
    }

    &.filtering:before {
        transform: scale3d(1, 1, 1);
        transform-origin: right 50%;
        transition: .5s transform var(--main-easing);
    }

    &.filtered:before {
        left: 0;
        transform: scale3d(0, 1, 1);
        transform-origin: left 50%;
        transition: 1s transform var(--main-easing);
    }
}

.products--item {
    position: relative;
    flex: 0 1 100%;
    padding: 0 15px;
    margin-bottom: 50px;

    @media (--min-tablet) {
        flex: 0 1 50%;
    }

    @media (--min-desktop) {
        flex: 0 1 33%;
    }

    @media (--min-desktop-xlarge) {
        flex: 0 1 25%;
        padding: 0 30px;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 33%;
    }
}

.article--link {
    position: relative;
    display: block;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: var(--dark-color);
        opacity: .15;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: var(--light-color);
        transform-origin: right 50%;
        transform: scale3d(0, 1, 1);
        transition: none;

        .filtered & {
            animation-name: filtered;
            animation-duration: 2s;
            transition: none;
        }
    }
}

.products--background {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    overflow: hidden;

    @media (--min-desktop) {
        padding-bottom: 116.67%;
    }
}

.products--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform .8s var(--main-easing);

    .article--link:hover & {
        transform: scale(1.2);
    }

    .filtered & {
        animation-name: image-scaling;
        animation-duration: 2s;
    }
}

.products--title {
    position: absolute;
    right: 30px;
    bottom: 40px;
    left: 30px;
    z-index: 5;
    font-size: 32px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.8px;
    color: var(--light-color);
}

.products--content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    width: 100%;
    padding: 15px 25px;
    color: var(--dark-color);
    background-color: var(--gray-light-color);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .25);
    opacity: 0;
    transform: translateX(-30px);
    transition: var(--main-transition);

    .article--link:hover & {
        opacity: 1;
        transform: translateX(0);
    }
}

.products--inner-title {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.3px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--dark-color);
    }
}

.products--attribute {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: 3px;

    span {
        margin: 0 4px;
        text-transform: uppercase;
    }

    > span:first-child {
        display: inline-block;
        min-width: 70px;
    }

    &.is-effect span + span {
        padding: 2px 5px;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 1px;
        color: var(--light-color);
        text-transform: uppercase;
        background-color: var(--dark-color);

        &.is-green {
            background: var(--green-color);
        }

        &.is-yellow {
            background: var(--yellow-color);
        }

        &.is-purple {
            background: var(--purple-color);
        }

        &.is-blue {
            background: var(--blue-color);
        }

        &.is-red {
            background: var(--red-color);
        }

        &.is-orange {
            background: var(--orange-color);
        }

        &.is-dark-blue {
            background: var(--dark-blue-color);
        }

        &.is-dark-red {
            background: var(--dark-red-color);
        }
    }

    &.is-color {
        display: flex;

        .products-attribute--content span {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: sub;
            margin-bottom: 5px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border: solid 1px var(--dark-color);
        }
    }
}

.products-attribute--content {
    flex: 0 1 100%;
}

.products--footer {
    flex: 0 0 100%;
    text-align: center;
}

.products--button.is-hidden {
    display: none;
}

.products--pagination {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: 1.2px;
}

.products--ribbon {
    position: absolute;
    top: 0;
    left: 30px;
    height: 25px;
    padding: 5px 10px 0;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.35;
    letter-spacing: 1.5px;
    color: var(--light-color);
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 3px 3px 0 color(var(--dark-color) a(.3));

    @media (--min-desktop) {
        left: 25px;
        height: 33px;
        font-size: 18px;
        line-height: 1.35;
        letter-spacing: 2px;
    }

    &.is-green {
        background: var(--green-color);
    }

    &.is-yellow {
        background: var(--yellow-color);
    }

    &.is-purple {
        background: var(--purple-color);
    }

    &.is-blue {
        background: var(--blue-color);
    }

    &.is-red {
        background: var(--red-color);
    }

    &.is-orange {
        background: var(--orange-color);
    }

    &.is-dark-blue {
        background: var(--dark-blue-color);
    }

    &.is-dark-red {
        background: var(--dark-red-color);
    }
}

.no-results--pretitle {
    margin-bottom: 30px;
    letter-spacing: 1.8px;
}

.no-results--title {
    margin-bottom: 20px;
    font-size: 28px;
    letter-spacing: .8px;

    @media (--min-desktop) {
        max-width: 650px;
        font-size: 32px;
        line-height: 1.11;
    }
}
