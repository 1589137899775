.social-share--cta {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1.5px;

    .is-product-detail &,
    .is-home & {
        padding: 10px 0;
        opacity: 0;
        transform: translateX(-20px);
        transition: none;

        a {
            display: flex;
            flex-flow: row nowrap;
            padding-bottom: 0;
            color: var(--light-color);
        }
    }

    .is-product-detail .is-visible &,
    .is-home .is-visible & {
        opacity: 1;
        transform: translate(0);
        transition: opacity .35s var(--main-easing) .3s, transform .35s var(--main-easing) .3s;
    }

    .tertiary-button span {
        text-transform: uppercase;

        &:after {
            content: none;

            @media (--min-desktop) {
                content: "";
                background: var(--light-color);
            }
        }
    }
}

.social-share {
    display: flex;
    margin-left: 10px;
    pointer-events: none;
}

.social-share--item {
    margin: 0 4px;
    font-size: 25px;
    color: var(--light-color);
    transition: var(--main-transition);

    @media (--min-desktop) {
        opacity: 0;
    }

    .social-share--cta:hover & {
        pointer-events: auto;
        opacity: 1;
    }

    &:nth-child(2) {
        transition: var(--main-transition) .2s;
    }

    &:nth-child(3) {
        transition: var(--main-transition) .3s;
    }

    &:nth-child(4) {
        transition: var(--main-transition) .4s;
    }

    svg {
        vertical-align: top;
        transition: var(--main-transition);

        @media (--min-desktop) {
            opacity: .7;
        }

        &:hover {
            @media (--min-desktop) {
                opacity: 1;
            }
        }
    }

    .is-product-detail & {
        color: var(--light-color);
    }

    .is-post-detail & {
        color: var(--dark-color);
    }
}
