@import 'wampkg-editinline';

.is-granted-edit-inline {
    padding-top: 80px;

    @media (--min-desktop) {
        padding-top: 150px;
    }

    .menu-mobile {
        top: 30px;
    }

    .menu {
        top: 30px;
    }
}
