.primary-button {
    display: inline-block;
    padding: 20px 35px;
    font-size: 13px;
    font-weight: normal;
    line-height: .9;
    letter-spacing: 2.3px;
    text-align: center;
    text-transform: uppercase;
    color: var(--light-color);
    background-color: var(--dark-color);
    border-radius: 0;
    transition: var(--main-transition);

    &:hover {
        color: var(--dark-color);
        background-color: var(--light-color);
    }
}

.secondary-button {
    display: inline-block;
    padding: 20px 35px;
    font-size: 13px;
    font-weight: normal;
    line-height: .9;
    letter-spacing: 2.3px;
    text-align: center;
    text-transform: uppercase;
    color: var(--dark-color);
    background-color: transparent;
    border: solid 1px var(--dark-color);
    border-radius: 0;
    transition: var(--main-transition);

    &:hover {
        color: var(--light-color);
        background-color: var(--dark-color);
    }

    &.is-inverted {
        color: var(--light-color);
        border-color: var(--light-color);

        &:hover {
            color: var(--dark-color);
            background-color: var(--light-color);
        }
    }

    &.is-solid {
        background-color: var(--light-color);

        &:hover {
            background-color: var(--dark-color);
        }
    }
}

.tertiary-button {
    position: relative;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    color: var(--black-color);
    line-height: .92;
    letter-spacing: 2.3px;

    span {
        position: relative;
    }

    span:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--black-color);
        transition: width var(--main-transition);

        @media (--min-desktop) {
            width: 0%;
        }
    }

    &:hover {
        span:after {
            width: 100%;
        }
    }
}
