.post--container {
    .container {
        @media (--min-desktop) {
            display: flex;
        }
    }
}

.post--content {
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 80px;
    border-bottom: 1px solid var(--dark-color);

    @media (--min-desktop) {
        flex: 0 1 calc(100% - 360px);
        min-height: 400px;
        margin-bottom: 0;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--dark-color);
    }

    h1,
    h2,
    h3 {
        margin-bottom: 35px;
        font-size: 20px;
        font-weight: 300;
        line-height: 1.25;
        letter-spacing: .8px;

        @media (--min-desktop) {
            font-size: 24px;
        }
    }

    p {
        position: relative;
        margin-bottom: 1.5em;
        font-size: 14px;
        line-height: 1.43;

        @media (--min-desktop) {
            font-size: 18px;
        }
    }

    a {
        color: var(--dark-color);
        text-decoration: underline;
    }

    blockquote {
        position: relative;
        margin-bottom: 50px;
        font-size: 24px;
        line-height: 1.3;

        @media (--min-desktop) {
            max-width: 580px;
            margin: 0 auto 80px;
            font-size: 28px;
        }

        &:before {
            content: '“';
            position: absolute;
            top: -20px;
            left: -10px;
            font-size: 32px;
        }

        &:after {
            content: '”';
            position: absolute;
            bottom: -20px;
            right: 0;
            font-size: 32px;
        }
    }

    img {
        height: auto !important;
        width: 100% !important;
    }

    p:first-child {
        margin-bottom: 1.5;
        font-size: 20px;

        @media (--min-desktop) {
            margin-bottom: 1.7em;
            font-size: 24px;
            line-height: 1.25;
        }

        &:first-letter {
            font-size: 72px;
            font-weight: 100;
            line-height: .4;
            letter-spacing: 2px;
        }
    }
}

.post--aside {
    @media (--min-desktop) {
        flex: 0 1 310px;
        width: 310px;
        margin-left: 50px;
    }
}

.post--newsletter {
    width: 90%;
    margin: 0 auto 50px;
    padding: 40px 20px;
    background: var(--gray-light-color);

    @media (--min-desktop) {
        width: 100%;
    }
}

.post--collection-cta {
    display: block;
    width: calc(100% + 40px);
    margin-bottom: 90px;
    margin-left: -20px;

    @media (--min-desktop) {
        width: 100%;
        margin-left: 0;
    }

    img {
        @media (--min-tablet) {
            display: block;
            margin: 0 auto;
        }
    }
}

.post--collection {
    padding-right: 10px;
    color: var(--dark-color);
    letter-spacing: 1.8px;
    text-align: right;

    @media (--min-tablet) {
        text-align: center;
    }

    @media (--min-desktop) {
        text-align: right;
    }
}
