@import "wampkg-law-info";

.cookie-law-info-bar p {
    max-width: 64%;

    @media (--min-desktop) {
        max-width: none;
    }
}

.cookie-law-close-button:after,
.cookie-law-close-button:before {
    content: none;
}

a.cookie-law-close-button {
    top: 12px;
    color: var(--light-color);
    text-decoration: none;
}
