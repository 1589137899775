.legal-page {
    padding: 50px 0;

    @media (--min-desktop) {
        padding: 170px 0 80px;
    }

    .container {
        @media (--min-desktop) {
            display: flex;
        }
    }
}

.legal-page--aside {
    @media (--min-desktop) {
        flex: 0 1 30%;
        flex: 0 1 calc(30% - 40px);
        margin-right: 40px;
    }
}

.legal--page--list {
    padding: 20px 0 30px;

    @media (--min-desktop) {
        padding: 0;
    }
}

.legal-page--item {
    display: block;
    padding: 15px;
    font-weight: 400;
    color: var(--dark-color);
    background: var(--gray-light-color);
    border-bottom: 1px solid var(--gray-medium-color);
    transition: var(--main-transition);

    &.is-selected {
        color: var(--light-color);
        background: var(--dark-color);
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        color: var(--light-color);
        background: var(--dark-color);
    }
}

.legal-page--content {
    @media (--min-desktop) {
        flex: 0 1 70%;
        flex: 0 1 calc(70% - 40px);
        margin-left: 40px;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.17;
        letter-spacing: .5px;
    }

    p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: .5px;
    }
}
