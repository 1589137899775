.history {
    position: relative;
    width: 100%;

    @media (--min-desktop) {
        padding: 170px 0;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background: var(--gray-light-color);

        @media (--min-desktop) {
            left: auto;
            right: 0;
            width: 50%;
            height: 100%;
        }
    }

    .container {
        position: relative;

        @media (--min-desktop) {
            display: flex;
            justify-content: space-between;
        }
    }
}

.history--media {
    @apply --load-animation;

    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 10px 25px 0 color(var(--dark-color) a(.25));

    @media (--min-desktop) {
        flex: 0 1 65%;
    }
}

.history--gallery {
    position: relative;
    width: 100%;
    height: 100%;
}

.history-media--item {
    position: relative;
    width: 100%;
    height: 200px;

    @media (--min-desktop) {
        height: 370px;
    }
}

.history-media--background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.history-media--content {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 144px;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.history-year--list {
    font-size: 100px;
    font-weight: 100;
    letter-spacing: .8px;
    color: var(--light-color);
}

.history-year--item {
    height: 0;
    pointer-events: none;
    opacity: 0;
    transition: .5s opacity var(--main-easing);

    &.is-active {
        height: 100%;
        opacity: 1;
        transition: .5s opacity var(--main-easing) .3s;
    }
}

.history--timeline {
    position: absolute;
    bottom: 0;
    left: 10px;
    display: flex;
    width: 100%;
    height: 6px;

    @media (--min-desktop) {
        left: 0;
        margin-top: 20px;
    }
}

.history-timeline--item {
    position: relative;
    flex: 0 1 auto;
    width: 100%;
    border-top: 2px solid var(--light-color);
    border-right: 2px solid var(--light-color);

    &:first-child {
        border-left: 2px solid var(--light-color);
    }

    &:last-child {
        border-right: none;
        border-top: none;
    }

    &.is-active span {
        opacity: 1;
        transition: .5s var(--main-easing) .8s;
    }

    span {
        position: absolute;
        top: -24px;
        left: -6px;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-bottom-color: var(--light-color);
        opacity: 0;
        transition: .5s var(--main-easing);

        &:after {
            content: '';
            position: absolute;
            left: -5px;
            top: 5px;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-top-color: var(--light-color);
        }
    }
}

.history--tooltip {
    position: absolute;
    left: 50%;
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .4px;
    text-align: center;
    transform: translateX(-50%);

    @media (--min-desktop) {
        display: none;
    }
}

.history--content {
    @media (--min-desktop) {
        flex: 0 1 30%;
    }
}

.history--gallery-counter {
    display: flex;
    align-items: center;
    padding: 20px 0;

    a {
        color: var(--dark-color);
    }
}

.history--gallery-prev,
.history--gallery-next {
    font-size: 30px;

    svg {
        vertical-align: middle;
    }
}

.history--gallery-count {
    font-size: 14px;
    letter-spacing: .4px;
}

.history-info--list {
    padding-bottom: 70px;
    height: 200px;

    @media (--min-desktop) {
        height: auto;
    }
}

.history-info--item {
    height: 0;
    opacity: 0;
    transition: .5s opacity var(--main-easing) .6s;

    &.is-active {
        height: 100%;
        opacity: 1;
        transition: .5s opacity var(--main-easing) .6s;
    }
}

.history--info-title {
    margin-bottom: 15px;
    font-size: 28px;
    letter-spacing: .6px;

    @media (--min-desktop) {
        font-size: 36px;
    }
}

.history--info-text {
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 1.43;

    @media (--min-desktop) {
        font-size: 18px;
    }
}
