.about-ksp {
    padding: 30px 0;

    @media (--min-desktop) {
        padding: 90px 0 30px;
    }
}

.about-ksp--title {
    position: relative;
    max-width: 195px;
    margin-bottom: 50px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;

    @media (--min-desktop) {
        max-width: none;
        margin: 0 auto 80px;
        font-size: 32px;
        text-align: center;
    }
}

.about-ksp--intro {
    position: relative;
    height: 240px;
}

.about-ksp--list {
    @media (--min-desktop) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 80px;
    }
}

.about-ksp--item {
    position: relative;
    margin-bottom: 80px;

    @media (--min-desktop) {
        flex: 0 1 calc(50% - 160px);
        margin: 0 80px 80px;
    }

    @media (--min-desktop-large) {
        flex: 0 1 calc(33.33% - 160px);
    }
}

.about-ksp--item-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-ksp--item-title {
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    line-height: 1;
    letter-spacing: 1.8px;
    color: var(--light-color);
    transform: translate(-50%, calc(-50% - 20px));
}

.about-ksp--content {
    padding: 30px 30px 40px 25px;
    margin-top: -100px;
    margin-left: 25px;
    background: var(--light-color);

    @media (--min-desktop) {
        margin-right: -60px;
    }
}

.about-ksp--item-text {
    max-width: 215px;
    font-size: 18px;
    line-height: 1.33;

    &.is-small {
        font-size: 14px;
    }
}
