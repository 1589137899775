.blog--grid {
    @media (--min-tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (--min-desktop) {
        margin-bottom: 80px;
    }

    @media (--min-desktop-xlarge) {
        margin-bottom: 100px;
    }
}

.blog--post {
    display: block;
    width: 90%;
    margin: 0 auto;
    color: var(--dark-color);

    @media (--min-tablet) {
        margin: 0 0 70px;
        flex: 0 1 45%;
        order: 0;

        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(4),
        &:nth-child(8) {
            flex: 0 1 40%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7) {
            flex: 0 1 50%;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(6) {
            margin-bottom: 0;
        }

        &:nth-child(3),
        &:nth-child(7) {
            margin-top: 75px;
        }
    }

    @media (--min-desktop) {
        &:nth-child(1),
        &:nth-child(7) {
            flex: 0 1 50%;
        }

        &:nth-child(2),
        &:nth-child(6) {
            flex: 0 1 40%;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(9),
        &:nth-child(10) {
            flex: 0 1 28%;
            margin-top: 75px;
        }

        &:nth-child(5),
        &:nth-child(8) {
            flex: 0 1 20%;
        }

        &:nth-child(7) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    @media (--min-desktop-xlarge) {
        margin: 0;

        &:nth-child(1),
        &:nth-child(10) {
            flex: 0 1 37%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(8),
        &:nth-child(9) {
            flex: 0 1 28%;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(14) {
            flex: 0 1 18%;
            margin-top: 75px;
        }

        &:nth-child(7),
        &:nth-child(11) {
            flex: 0 1 20%;
        }

        &:nth-child(3),
        &:nth-child(9),
        &:nth-child(10) {
            margin-top: 0;
        }
    }
}

.blog-post--media {
    position: relative;
    width: 100%;
    height: 245px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform .8s var(--main-easing);

    @media (--min-tablet) {
        .blog--post:nth-child(1) &,
        .blog--post:nth-child(5) &,
        .blog--post:nth-child(4) &,
        .blog--post:nth-child(8) & {
            height: 275px;
        }

        .blog--post:nth-child(2) &,
        .blog--post:nth-child(3) &,
        .blog--post:nth-child(6) &,
        .blog--post:nth-child(7) & {
            height: 200px;
        }
    }

    @media (--min-desktop) {
        height: 460px;

        .blog--post:nth-child(1) &,
        .blog--post:nth-child(7) & {
            height: 400px;
        }

        .blog--post:nth-child(2) &,
        .blog--post:nth-child(6) & {
            height: 260px;
        }

        .blog--post:nth-child(3) &,
        .blog--post:nth-child(4) &,
        .blog--post:nth-child(9) &,
        .blog--post:nth-child(10) & {
            height: 200px;
        }

        .blog--post:nth-child(5) &,
        .blog--post:nth-child(8) & {
            height: 275px;
        }
    }

    @media (--min-desktop-xlarge) {
        .blog--post:nth-child(1) &,
        .blog--post:nth-child(10) & {
            height: 400px;
        }

        .blog--post:nth-child(2) &,
        .blog--post:nth-child(3) &,
        .blog--post:nth-child(8) &,
        .blog--post:nth-child(9) & {
            height: 260px;
        }

        .blog--post:nth-child(4) &,
        .blog--post:nth-child(5) &,
        .blog--post:nth-child(6) &,
        .blog--post:nth-child(12) &,
        .blog--post:nth-child(13) &,
        .blog--post:nth-child(14) & {
            height: 200px;
        }

        .blog--post:nth-child(7) &,
        .blog--post:nth-child(11) & {
            height: 275px;
        }
    }

    .blog--post:hover & {
        transform: scale(.95);
    }
}

.blog-post--category {
    position: absolute;
    top: 113px;
    right: -136px;
    width: 245px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transform: rotate(-90deg);

    @media (--min-tablet) {
        .blog--post:nth-child(1) &,
        .blog--post:nth-child(5) & {
            top: 143px;
        }

        .blog--post:nth-child(2) &,
        .blog--post:nth-child(3) &,
        .blog--post:nth-child(6) &,
        .blog--post:nth-child(7) & {
            top: 69px;
        }
    }

    @media (--min-desktop) {
        top: 220px;
        right: -246px;
        width: 460px;

        .blog--post:nth-child(1) &,
        .blog--post:nth-child(7) & {
            top: 160px;
        }

        .blog--post:nth-child(2) &,
        .blog--post:nth-child(6) & {
            top: 20px;
        }

        .blog--post:nth-child(3) &,
        .blog--post:nth-child(4) &,
        .blog--post:nth-child(9) &,
        .blog--post:nth-child(10) & {
            width: 200px;
            right: -112px;
            top: 90px;
        }

        .blog--post:nth-child(5) &,
        .blog--post:nth-child(8) & {
            width: 275px;
            right: -152;
            top: 128px;
        }
    }

    @media (--min-desktop-xlarge) {
        .blog--post:nth-child(2) &,
        .blog--post:nth-child(3) &,
        .blog--post:nth-child(8) &,
        .blog--post:nth-child(9) & {
            width: 260px;
            right: -144px;
            top: 120px;
        }

        .blog--post:nth-child(4) &,
        .blog--post:nth-child(5) &,
        .blog--post:nth-child(6) &,
        .blog--post:nth-child(12) &,
        .blog--post:nth-child(13) &,
        .blog--post:nth-child(14) & {
            width: 200px;
            right: -112px;
            top: 90px;
        }

        .blog--post:nth-child(1) &,
        .blog--post:nth-child(10) & {
            width: 400px;
            right: -214px;
            top: 190px;
        }

        .blog--post:nth-child(7) &,
        .blog--post:nth-child(11) & {
            width: 275px;
            right: -150px;
            top: 128px;
        }
    }
}

.blog-post--content {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media (--min-tablet) {
        margin-bottom: 0;
    }
}

.blog-post--info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px;
    letter-spacing: 1.8px;
}

.blog-post--name {
    @media (--min-desktop-xxlarge) {
        display: flex;
        justify-content: space-between;
    }
}

.blog-post--title {
    margin-bottom: 20px;
    font-size: 28px;
    letter-spacing: .6px;
    line-height: 1.13;
    transition: text-decoration .3s var(--secondary-easing);

    @media (--min-tablet) {
        margin-bottom: 0;
    }

    @media (--min-desktop-xxlarge) {
        margin-bottom: 0;
    }

    .blog--post:hover & {
        text-decoration: underline;
    }
}

.blog-newsletter {
    width: 90%;
    padding: 40px 20px;
    margin: 0 auto 40px;
    background: var(--gray-light-color);

    @media (--min-tablet) {
        order: 1;
        width: 100%;
    }

    @media (--min-desktop) {
        display: flex;
        justify-content: space-between;
        padding: 40px 60px;
        margin: 70px auto;
    }

    @media (--min-desktop-xlarge) {
        margin: 100px auto;
    }
}

.pagination {
    margin-bottom: 40px;
}

.pagination--list {
    display: flex;
    justify-content: center;
}

.pagination--item {
    padding-left: 10px;
    font-size: 22px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: .7px;
    text-align: center;

    &.is-active {
        font-weight: 900;
    }

    a {
        color: var(--dar--color);
    }

    &.is-arrow {
        display: flex;
        align-items: center;
        font-size: 12px;
    }
}
