.values {
    position: relative;
    width: 100%;

    @media (--min-desktop) {
        display: flex;
        flex-wrap: wrap;
        padding: 120px 0;
    }
}

.values--item {
    position: relative;
    padding-top: 120px;
    margin-bottom: 30px;

    @media (--min-desktop) {
        flex: 0 1 45%;
        padding-top: 20px;
        margin-right: 30px;
        margin-bottom: 0;
    }
}

.values--media {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        width: 330px;
        height: 420px;
    }
}

.values--content {
    @apply --load-animation;

    position: relative;
    padding: 0 15px 0 30px;

    @media (--min-desktop) {
        padding-left: 300px;
    }
}

.values--intro {
    margin-left: 53%;

    @media (--min-desktop) {
        margin-left: 0;
    }
}

.values--icon {
    font-size: 60px;
}

.values--title {
    margin-bottom: 35px;
    font-size: 28px;
}

.values--text {
    max-width: 355px;

    @media (--min-desktop) {
        font-size: 18px;
    }
}
