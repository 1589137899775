.browser {
    padding: 50px 0 20px;
    text-align: center;
    color: var(--browsehappy-color);
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: .25px;
    font-family: Arial, Verdana, sans-serif;
    border-bottom: 1px solid var(--browsehappy-background);
    background-color: var(--browsehappy-background);
}

.browser-container {
    width: 90%;
    max-width: 920px;
    margin: 0 auto;
}

.browser-title {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
}

.browser-text {
    margin-bottom: 30px;
}

.browser-list {
    margin: 0;
    padding: 0;
}

.browser-item {
    display: inline-block;
    max-width: 226px;

    a {
        display: block;
        padding: 0 20px;
        color: var(--browsehappy-color);
        text-decoration: none;
    }

    div {
        padding: 10px;
        font-size: 14px;
        font-weight: 100;
        text-transform: uppercase;
        border-radius: 5px;
        background-color: var(--browsehappy-background-secondary);
        transition: .2s ease;
    }

    .browser-website {
        display: block;
        padding: 15px 15px 0;
        font-size: 13px;
        transition: .2s ease;
        opacity: 0;
    }

    a:hover {
        div {
            transform: scale(1.1);
            box-shadow: 0 10px 20px rgba(#000000, .15);
        }

        .browser-website {
            opacity: 1;
        }
    }
}

.browser-image {
    display: block;
    width: 138px;
    height: 130px;
    margin: 0 auto 5px;
    background-image: url('/img/browser-sprite.png');
    background-repeat: no-repeat;
    background-size: auto 130px;

    .browser-chrome & {
        background-position: 0 0;
    }

    .browser-firefox & {
        background-position: -138px 0;
    }

    .browser-edge & {
        background-position: -278px 0;
    }

    .browser-explorer & {
        background-position: -410px 0;
    }
}
