.home-header {
    position: relative;
    overflow: hidden;
}

.home-header--item {
    position: relative;
    width: 100%;
    min-height: 90vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (--min-desktop-xlarge) {
        min-height: 80vh;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-image: linear-gradient(to bottom, color(var(--dark-color) a(70%)), color(var(--dark-color) a(0%)) 60%);

        @media (--min-desktop) {
            background-image: linear-gradient(to right, color(var(--dark-color) a(60%)), color(var(--dark-color) a(0%)) 60%);
        }
    }
}

.home-header--item-pretitle {
    margin-bottom: 10px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    opacity: 0;
    transform: translateX(-20px);

    .is-active & {
        opacity: 1;
        transform: translateX(0);
        transition: opacity .3s var(--main-easing), transform .3s var(--main-easing);
    }
}

.home-header--item-title {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 1.15;
    letter-spacing: .6px;
    opacity: 0;
    transform: translateX(-20px);

    .is-active & {
        opacity: 1;
        transform: translateX(0);
        transition: opacity .3s var(--main-easing) .1s, transform .3s var(--main-easing) .1s;
    }

    @media (--min-desktop) {
        font-size: 36px;
        letter-spacing: .8px;
    }
}

.home-header--item-content {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: .5px;
    opacity: 0;
    transform: translateX(-20px);

    .is-active & {
        opacity: 1;
        transform: translateX(0);
        transition: opacity .3s var(--main-easing) .2s, transform .3s var(--main-easing) .2s;
    }

    @media (--min-desktop) {
        /* max-width: 35%; */
        font-size: 18px;
    }

    p {
        margin-bottom: 1em;
    }
}

.home-header--item-cta {
    display: inline-block;
    margin-top: 20px;
    color: var(--light-color);
    opacity: 0;
    transform: translateX(-20px);

    .is-active & {
        opacity: 1;
        transform: translateX(0);
        transition: opacity .3s var(--main-easing) .3s, transform .3s var(--main-easing) .3s;
    }

    &:after {
        width: 100%;
        background: var(--light-color);
    }

    &:hover:after {
        width: 100%;
        animation: underlineHover 1s var(--main-easing);
    }
}

.home-header--content {
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    color: var(--light-color);
    pointer-events: none;

    @media (--min-desktop) {
        justify-content: center;
    }

    .container {
        position: relative;
        padding-top: 70px;
        margin: 0 auto;

        @media (--max-tablet) {
            max-width: 80%;
        }

        @media (--min-tablet) {
            padding-top: 150px;
        }

        @media (--min-desktop) {
            padding-top: 0;
        }
    }

    .info-window {
        opacity: 0;
        transform: translateY(100%);
        transition: transform var(--main-transition);

        @media (--min-desktop) {
            transform: translateY(-50%) translateX(100%);
        }

        &.is-active {
            opacity: 1;
            transform: translateY(0) translateX(-50%);

            @media (--min-desktop) {
                transform: translateY(-50%);
            }
        }

        &.is-hidding {
            opacity: 0;
            transform: translateY(100%);
            transition: opacity .2s var(--secondary-easing), transform .01s ease .3s;

            @media (--min-desktop) {
                transform: translateY(-50%) translateX(100%);
            }
        }
    }

    .info-window-none {
        display: none;
    }
}

.home-header--down {
    position: absolute;
    bottom: 70px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 2.3px;
    color: var(--light-color);
    text-transform: uppercase;

    .container {
        padding-top: 100px;
        margin: 0 auto;

        @media (--max-tablet) {
            max-width: 80%;
        }

        @media (--min-tablet) {
            padding-top: 150px;
        }

        @media (--min-desktop) {
            padding-top: 0;
        }
    }

    span {
        position: absolute;
        top: 0;
        right: 0;
        margin-left: 5px;
        animation: arrow-down 1s alternate infinite;
    }
}

.home-header--text {
    position: relative;
    display: inline-block;
    padding-right: 30px;
}

.home-header--caption {
    height: 0;
    max-width: 500px;
    overflow: hidden;
    pointer-events: auto;
    transition: opacity .2s var(--secondary-easing);

    &.is-active {
        height: auto;
        overflow: visible;
    }

    &.is-hidding {
        opacity: 0;
    }
}

.home-header--navigation {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: .4px;
    opacity: 0;
    pointer-events: auto;
    transition: opacity .3s var(--secondary-easing);

    &.is-active {
        opacity: 1;
    }
}

.home-header--arrow {
    padding: 0;
    margin: 0;
    font-size: 25px;
    color: var(--light-color);
    background-color: transparent;
    border: none;
    outline: none;
    cursor: hand;
    cursor: pointer;

    svg {
        display: block;
    }
}
