/* Breakpoints */
@custom-media --max-mobile (width < 600px);
@custom-media --min-tablet (width >= 600px);
@custom-media --max-tablet (width < 960px);
@custom-media --min-desktop (width >= 960px);
@custom-media --max-desktop (width < 1100px);
@custom-media --min-desktop-large (width >= 1100px);
@custom-media --min-desktop-xlarge (width >= 1280px);
@custom-media --min-desktop-xxlarge (width >= 1500px);
@custom-media --min-desktop-xxxlarge (width >= 1600px);
@custom-media --panoramic-laptop (min-width: 1281px) and (max-width: 1439px) and (min-device-aspect-ratio: 8/5);

:root {
    /* Colors */
    --main-color: #0099CC;
    --light-color: #FFFFFF;
    --gray-medium-color: #9E9E9E;
    --gray-light-color: #F5F5F5;
    --dark-color: #1B1C1D;
    --black-color: #000000;
    --disabled-color: #BEBEBE;
    --green-color: #256256;
    --yellow-color: #FBB240;
    --purple-color: #474B6E;
    --blue-color: #4564A2;
    --red-color: #C74032;
    --orange-color: #ED6F33;
    --dark-blue-color: #30333F;
    --dark-red-color: #702D3A;

    /* fonts */
    --primary-font: RealHeadPro, Arial, sans-serif;

    /* Others */
    --main-easing: cubic-bezier(.42, 0, .58, 1);
    --secondary-easing: cubic-bezier(.16, .65, .15, 1);
    --main-transition: .35s var(--main-easing);

    /* Browsehappy */
    --browsehappy-background: #3A4A5B;
    --browsehappy-background-secondary: #2E3B49;
    --browsehappy-color: #FFFFFF;

    /* cookies */
    --cookie-bar-background: #FFFFFF;
    --cookie-bar-text: #1B1C1D;
}
