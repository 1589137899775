/* Create @font-face with transfonter tool (transfonter.org) with TTF, WOFF, SVG, EOT and WOFF2 formats, add here the generated styles and the fonts to "/web/fonts" folder */
@font-face {
    font-family: 'RealHeadPro';
    src:
        url('/fonts/RealHeadPro-Light.woff2') format('woff2'),
        url('/fonts/RealHeadPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RealHeadPro';
    src:
        url('/fonts/RealHeadPro-Semilight.woff2') format('woff2'),
        url('/fonts/RealHeadPro-Semilight.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RealHeadPro';
    src: url('/fonts/RealHeadPro-Ultralight.eot');
    src:
        url('/fonts/RealHeadPro-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/RealHeadPro-Ultralight.woff2') format('woff2'),
        url('/fonts/RealHeadPro-Ultralight.woff') format('woff'),
        url('/fonts/RealHeadPro-Ultralight.ttf') format('truetype'),
        url('/fonts/RealHeadPro-Ultralight.svg#RealHeadPro-Ultralight') format('svg');
    font-weight: 200;
    font-style: normal;
}
