.catalogs {
    @media (--min-desktop) {
        display: flex;
    }
}

.catalog--grid {
    @media (--min-desktop) {
        flex: 1 1 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 60px;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 80%;
    }
}

.catalog-collection--container {
    @media (--min-desktop) {
        flex: 1 1 auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}

.catalog--grid-title {
    flex: 1 1 100%;
    padding: 40px 0 40px 30px;
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.catalog--item {
    position: relative;
    flex: 0 1 100%;
    padding: 0 30px;
    margin-bottom: 50px;
    font-size: 22px;

    @media (--min-desktop) {
        flex: 0 1 50%;
    }

    @media (--min-desktop-xlarge) {
        flex: 0 1 33%;
    }

    @media (--min-desktop-xxlarge) {
        flex: 0 1 25%;
    }

    @media (--panoramic-laptop) {
        flex: 0 1 26%;
    }
}

.catalog--link {
    position: relative;
    display: block;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: var(--dark-color);
        opacity: .15;
    }
}

.catalog--background {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 116.67%;
    overflow: hidden;
}

.catalog--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .8s var(--main-easing);

    .catalog--link:hover & {
        transform: scale(1.2);
        filter: grayscale(100%);
    }
}

.catalog--title {
    position: absolute;
    right: 30px;
    bottom: 40px;
    left: 30px;
    z-index: 5;
    font-size: 32px;
    font-weight: 300;
    line-height: 1.13;
    letter-spacing: 1.8px;
    color: var(--light-color);
    opacity: 1;
    transition: all .4s var(--main-easing) .5s;

    @media (--min-desktop) {
        font-size: 22px;
    }

    @media (--min-desktop-xlarge) {
        font-size: 32px;
    }

    .catalog--link:hover & {
        opacity: 0;
        transition: all .4s var(--main-easing);
    }
}

.catalog--size {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: 100%;
    font-size: 14px;
    color: var(--light-color);
    letter-spacing: 3.4px;
    opacity: 0;
    transition: all .4s var(--main-easing);

    .catalog--link:hover & {
        opacity: 1;
        transition: all .4s var(--main-easing) .5s;
    }

    svg {
        margin-right: 15px;
        font-size: 18px;
    }
}

.catalog--download {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    text-align: center;
    font-size: 18px;
    letter-spacing: 4px;
    color: var(--light-color);
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all .4s var(--main-easing);

    @media (--min-desktop-xlarge) {
        font-size: 30px;
    }

    .catalog--link:hover & {
        opacity: 1;
        transition: all .4s var(--main-easing) .5s;
    }

    svg {
        vertical-align: middle;
    }
}

.catalog--new {
    position: absolute;
    top: 0;
    left: 32px;
    width: 49px;
    height: 25px;
    padding-top: 5px;
    font-size: 13px;
    color: var(--light-color);
    text-align: center;
    background: var(--green-color);
    box-shadow: 0 3px 3px 0 color(var(--dark-color) a(.3));

    @media (--min-desktop) {
        left: 44px;
        width: 65px;
        height: 33px;
        font-size: 18px;
    }
}

.catalog-loader {
    position: relative;
    pointer-events: none;
    opacity: 0;
    transition: var(--main-transition);

    &.is-active {
        pointer-events: auto;
        opacity: 1;
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 30px;
    }

    .lds-ellipsis div {
        position: absolute;
        top: 0;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: color(var(--dark-color) a(80%));
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 .6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 .6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 .6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 .6s infinite;
    }
}
