.error-page {
    position: relative;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 360px;

    @media (--min-desktop) {
        padding-top: 80px;
    }

    .container {
        position: relative;
    }
}

.error-page--background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, transparent, var(--black-color));
    }
}

.error-page--content {
    padding-top: 40px;
    color: var(--light-color);

    @media (--min-desktop) {
        padding-top: 190px;
    }
}

.error-page--title {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 300;

    @media (--min-desktop) {
        font-size: 36px;
    }

    span {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: 1.8px;
    }
}

.error-page--subtitle {
    max-width: 300px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .5px;
    line-height: 1.43;

    @media (--min-desktop) {
        font-size: 18px;
    }
}

.error-page--link {
    color: var(--light-color);

    span:after {
        background: var(--light-color);
    }
}
