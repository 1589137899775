/* Menu Styles */
.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1000;
    background: var(--dark-color);
    box-shadow: 0 2px 12px color(var(--dark-color) a(50%));

    @media (--min-desktop) {
        height: 80px;
    }
}

.menu-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (--max-tablet) {
        padding-right: 0;
        padding-left: 0;
    }

    @media (--min-desktop) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.menu-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--light-color);

    @media (--min-desktop) {
        position: absolute;
        z-index: 2;
    }
}

.menu-logo {
    display: block;
    width: 140px;
    height: 50px;
    padding: 10px 20px;
    color: inherit;

    @media (--min-desktop) {
        width: 190px;
        height: auto;
        padding: 10px 0;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.menu-actions--link {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-size: 12px;
    line-height: 1.1;
    letter-spacing: 1.1px;
    color: inherit;

    svg {
        margin-right: 5px;
        font-size: 12px;
    }
}

.menu-search {
    .is-menu-open &,
    .is-search-open &,
    .is-locale-open & {
        display: none;
    }

    @media (--min-desktop) {
        display: none;
    }

    svg {
        font-size: 10px;
    }
}

.menu-open {
    .is-menu-open &,
    .is-search-open &,
    .is-locale-open & {
        display: none;
    }

    @media (--min-desktop) {
        display: none;
    }
}

.menu-close {
    display: none;

    .is-menu-open &,
    .is-search-open &,
    .is-locale-open & {
        display: flex;

        @media (--min-desktop) {
            display: none;
        }
    }

    svg {
        font-size: 10px;
    }
}

.menu-dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 0;
    overflow: hidden;
    background: var(--dark-color);
    transition: .35s var(--secondary-easing);

    @media (--min-desktop) {
        top: 77px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 12px;
            background-image: linear-gradient(to bottom, color(var(--black-color) a(35%)), color(var(--dark-color) a(0%)));
        }
    }
}

.menu-content {
    display: flex;
    flex-direction: column-reverse;

    @media (--min-desktop) {
        position: static;
        top: auto;
        width: 100%;
        height: auto;
        overflow: auto;
        flex-direction: column;
        align-items: flex-end;
        background: transparent;
        transition: none;
    }

    &.has-submenu-open {
        @media (--min-desktop) {
            height: 550px;
        }
    }

    .is-menu-open & {
        height: calc(100vh - 50px);
        transition: height .35s var(--main-easing);
        z-index: 1;

        @media (--min-desktop) {
            height: auto;
            transition: none;
            z-index: auto;
        }
    }
}

.topbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    margin-bottom: 90px;
    color: var(--light-color);

    @media (--min-desktop) {
        flex-direction: row;
        margin-bottom: 0;
    }
}

.topbar-zyx {
    padding: 40px;
    font-size: 14px;
    color: inherit;
    opacity: 0;
    transition: 0s linear 0s;

    @media (--min-desktop) {
        position: relative;
        padding: 10px 30px 10px;
        margin-right: 20px;
        opacity: 1;
        transition: none;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 90%;
            border-right: 1px solid var(--light-color);
            opacity: .5;
        }
    }

    .is-menu-open & {
        opacity: 1;
        transition: .35s var(--main-easing) .5s;

        @media (--min-desktop) {
            transition: none;
        }
    }

    svg {
        display: block;
    }
}

.topbar-nav {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 0s linear 0s;

    @media (--min-desktop) {
        margin-right: 5px;
        opacity: 1;
        transition: none;
    }

    .is-menu-open & {
        opacity: 1;
        transition: .35s var(--main-easing) .6s;

        @media (--min-desktop) {
            transition: none;
        }
    }
}

.topbar-nav--link {
    display: flex;
    align-items: flex-start;
    padding: 5px 10px;
    color: inherit;
    font-size: 12px;
    line-height: 1.1;
    letter-spacing: 1.1px;

    &:focus {
        outline: none;
    }

    svg {
        margin-right: 5px;
        font-size: 10px;
    }

    i {
        position: relative;
        margin-right: 5px;
        margin-top: -2px;
        font-size: 9px;
        line-height: 1.5;
        text-transform: uppercase;
        font-style: normal;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px solid var(--light-color);
            opacity: .5;
        }
    }
}

.topbar-nav--search {
    display: none;

    @media (--min-desktop) {
        display: block;
    }
}

.nav {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-top: 45px;
    text-align: center;

    @media (--min-desktop) {
        flex-direction: row;
        margin-top: 0;
    }
}

.nav-item {
    border-bottom: 1px solid color(var(--light-color) a(30%));
    opacity: 0;
    transition: 0s linear 0s;

    @media (--min-desktop) {
        height: 43px;
        border: none;
        opacity: 1;
        transition: none;
    }

    .is-menu-open & {
        opacity: 1;
        transition: .35s var(--main-easing) 0s;

        @media (--min-desktop) {
            transition: none;
        }

        &:nth-child(2) {
            transition-delay: .1s;
        }

        &:nth-child(3) {
            transition-delay: .2s;
        }

        &:nth-child(4) {
            transition-delay: .3s;
        }

        &:nth-child(5) {
            transition-delay: .4s;
        }

        &:nth-child(6) {
            transition-delay: .5s;
        }
    }

    &:first-child {
        border-top: 1px solid color(var(--light-color) a(30%));

        @media (--min-desktop) {
            border: none;
        }
    }
}

.nav-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 4;
    color: var(--light-color);
    text-transform: uppercase;

    @media (--min-desktop) {
        padding: 15px;
        line-height: 1.1;
    }

    .has-submenu & > span {
        margin-right: 5px;
        margin-left: 15px;

        @media (--min-desktop) {
            margin-left: 0;
        }
    }

    svg {
        display: block;
        font-size: 9px;

        @media (--min-desktop) {
            transform: rotate(90deg);
            transition: transform var(--main-transition);
        }

        /* .nav-item:hover & {
            @media (--min-desktop) {
                transform: rotate(-90deg);
            }
        } */
    }

    span {
        @media (--min-desktop) {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                bottom: -5px;
                width: 0;
                height: 1px;
                background: var(--light-color);
                transition: var(--main-transition);

                /* .nav-item:hover & {
                    width: 100%;
                } */
            }
        }
    }
}

.nav-submenu {
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: calc(100vh - 50px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: var(--gray-light-color);
    transition: left .35s var(--secondary-easing), width 0s linear .3s;

    @media (--min-desktop) {
        top: 80px;
        left: 0;
        width: 100%;
        height: 0;
        align-items: center;
        justify-content: center;
        transition: height .35s var(--secondary-easing);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 12px;
            background-image: linear-gradient(to bottom, color(var(--black-color) a(35%)), color(var(--dark-color) a(0%)));
        }
    }

    &.is-submenu-open {
        left: 25px;
        width: calc(100% - 25px);
        transition: left .35s var(--main-easing);

        @media (--min-desktop) {
            left: 0;
            width: 100%;
            height: 480px;
            z-index: 2;
            transition: height .35s var(--main-easing);
        }
    }

    /* .nav-item:hover & {
        @media (--min-desktop-large) {
            left: 0;
            width: 100%;
            height: 480px;
            z-index: 2;
            transition: height .35s var(--main-easing);
        }
    } */
}

.nav-submenu--close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: 20px 15px;
    color: var(--dark-color);

    @media (--min-desktop) {
        display: none;
    }

    svg {
        display: block;
    }

    span {
        display: block;
        font-size: 12px;
        line-height: 1.1;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}

.nav-submenu--list {
    flex: 1 1 auto;
    overflow: scroll;

    @media (--min-desktop) {
        max-width: 900px;
        display: flex;
        flex-flow: row wrap;
        flex: 0 1 auto;
        overflow: hidden;
    }
}

.nav-submenu--link {
    display: flex;
    align-items: center;
    padding: 5px 0 5px 35px;
    text-align: left;
    color: inherit;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition: 0s linear 0s;

    @media (--min-desktop) {
        position: relative;
        align-items: flex-start;
        flex-direction: column-reverse;
        flex: 0 1 33%;
        padding: 20px 0;
        transform: translate3d(0, 0, 0);
    }

    /* .nav-item:hover &, */
    .is-submenu-open & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .35s var(--main-easing) 0s;

        &:nth-child(2) {
            transition-delay: .1s;
        }

        &:nth-child(3) {
            transition-delay: .2s;
        }

        &:nth-child(4) {
            transition-delay: .3s;
        }

        &:nth-child(5) {
            transition-delay: .4s;
        }

        &:nth-child(n+6) {
            transition-delay: .5s;
        }
    }

    .is-about & {
        @media (--min-desktop) {
            flex: 0 1 25%;
        }
    }
}

.nav-submenu--link-content {
    flex: 1 1 auto;
    margin-right: 20px;

    @media (--min-desktop) {
        margin-right: 0;
    }
}

.nav-submenu--link-title {
    position: relative;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 3px;
    text-transform: uppercase;
    z-index: 1;

    @media (--min-desktop) {
        position: absolute;
        top: 40px;
        left: 25px;
        max-width: 140px;
        padding: 2px 0;
        color: var(--light-color);
    }

    span {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            border-bottom: 1px solid var(--light-color);
            transition: width var(--main-transition);

            /* .nav-submenu--link:hover & {
                @media (--min-desktop) {
                    width: 100%;
                }
            } */
        }
    }
}

.nav-submenu--link-text {
    font-size: 12px;
    line-height: 1.15;
    letter-spacing: .4px;

    @media (--min-desktop) {
        max-width: 220px;
        margin-top: 12px;
        margin-left: 25px;
        font-size: 16px;
    }
}

.nav-submenu--link-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 90px;
    min-height: 65px;
    font-size: 12px;
    color: var(--light-color);

    @media (--min-desktop) {
        width: 165px;
        min-height: 90px;
        display: block;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: color(var(--dark-color) a(.25));
    }

    span {
        margin-right: 5px;
        margin-left: 10px;
        letter-spacing: 3px;
        text-transform: uppercase;

        @media (--min-desktop) {
            display: none;
        }
    }

    svg {
        font-size: 10px;

        @media (--min-desktop) {
            display: none;
        }
    }
}

.locale-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .is-locale-open & {
        height: calc(100vh - 50px);
        z-index: 2;
        padding: 0 35px 50px;
        transition: height .35s var(--main-easing);

        @media (--min-desktop) {
            height: 450px;
            max-height: calc(100vh - 77px);
            padding: 0 50px 50px;
        }
    }
}

.locale-content--close {
    display: none;

    @media (--min-desktop) {
        position: absolute;
        top: 35px;
        right: 35px;
        width: 45px;
        height: 45px;
        display: block;
        padding: 15px;
        color: var(--light-color);
        opacity: 1;

        /* &:hover {
            animation: fadePulse 2s linear infinite alternate;
        } */
    }
}

.locale-content--label {
    margin-bottom: 80px;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: 2.2px;
    color: var(--light-color);
    opacity: 0;
    transition: 0s linear 0s;

    .is-locale-open & {
        opacity: 1;
        transition: .35s var(--main-easing) .15s;
    }
}

.locale-content--list {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--min-desktop) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.locale-content--link {
    position: relative;
    padding: 7px 0;
    letter-spacing: 1.8px;
    color: var(--light-color);
    opacity: 0;
    transition: 0s linear 0s;

    @media (--min-desktop) {
        margin: 20px 80px;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        border-bottom: 1px solid var(--light-color);
        transition: width var(--main-transition);
    }

    .is-locale-open & {
        opacity: 1;
        transition: .35s var(--main-easing) .25s;

        &:nth-child(2) {
            transition-delay: .35s;
        }

        &:nth-child(3) {
            transition-delay: .45s;
        }
    }

    &.is-selected:before {
        width: 100%;
    }

    /* &:hover {
        &:before {
            width: 100%;
        }
    } */

    & + a {
        margin-top: 50px;

        @media (--min-desktop) {
            margin-top: 20px;
        }
    }
}

.search-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    .is-search-open & {
        height: calc(100vh - 50px);
        z-index: 1;
        padding: 0 35px 70px;
        transition: height .35s var(--main-easing);

        @media (--min-desktop) {
            height: 450px;
            max-height: calc(100vh - 77px);
            padding: 0 50px 50px;
        }
    }
}

.search-content--close {
    display: none;

    @media (--min-desktop) {
        position: absolute;
        top: 35px;
        right: 35px;
        width: 45px;
        height: 45px;
        display: block;
        padding: 15px;
        color: var(--light-color);
        opacity: 1;

        /* &:hover {
            animation: fadePulse 2s linear infinite alternate;
        } */
    }
}

.search-content--label {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: 2.2px;
    color: var(--light-color);
    opacity: 0;
    transition: 0s linear 0s;

    @media (--min-desktop) {
        width: 600px;
        max-width: 100%;
        margin: 0 auto 20px;
    }

    .is-search-open & {
        opacity: 1;
        transition: .35s var(--main-easing) .15s;
    }
}

.search-content--field {
    display: flex;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: 2.2px;
    border-bottom: 1px solid var(--light-color);
    opacity: 0;
    transition: 0s linear 0s;

    @media (--min-desktop) {
        width: 600px;
        max-width: 100%;
        margin: 0 auto;
    }

    .is-search-open & {
        opacity: 1;
        transition: .35s var(--main-easing) .25s;
    }

    input[type=search] {
        width: calc(100% - 18px);
        flex: 1 1 auto;
        padding: 5px 20px 5px 0;
        color: var(--light-color);
        background-color: transparent;
    }

    button[type=submit] {
        flex: 0 0 18px;
        font-size: 14px;
        padding: 0;
        color: var(--light-color);
        background: transparent;
        border: none;
        cursor: pointer;

        /* &:hover {
            animation: fadePulse 2s linear infinite alternate;
        } */

        svg {
            display: block;
        }
    }
}
