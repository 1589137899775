.about-description {
    position: relative;
    width: 100%;
    padding: 40px 0;

    @media (--min-desktop) {
        padding: 70px 0;
    }

    &.has-gray-background {
        background-color: var(--gray-light-color);
    }

    &.is-reversed .container {
        flex-direction: row-reverse;
    }

    .container {
        @media (--min-desktop) {
            display: flex;
        }
    }
}

.about-description--media {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        flex: 0 1 40%;
        height: 420px;
    }
}

.about-description--content {
    @apply --load-animation;

    margin-top: -50px;

    @media (--min-desktop) {
        flex: 0 1 60%;
        margin-top: 0;
        margin-left: -100px;
    }

    .is-reversed & {
        @media (--min-desktop) {
            margin-left: 0;
            margin-right: -100px;
        }
    }
}

.about-description--icon {
    font-size: 80px;
}

.about-description--title {
    max-width: 300px;
    margin-bottom: 20px;
    font-size: 28px;
    letter-spacing: .6px;
    line-height: 1.14;

    @media (--min-desktop) {
        margin-bottom: 30px;
        font-size: 36px;
    }
}

.about-description--text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: .5px;

    @media (--min-desktop) {
        display: flex;
        font-size: 18px;
    }
}

.about-description--paragraph {
    @media (--min-desktop) {
        flex: 0 1 45%;
    }

    &:last-child {
        @media (--min-desktop) {
            margin-left: 100px;
        }
    }
}
