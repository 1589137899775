.login {
    padding-top: 50px;
    padding-bottom: 20px;

    @media (--min-desktop) {
        padding-top: 80px;
    }
}

.login-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-family: var(--primary-font);
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    letter-spacing: .6px;
    color: var(--dark-color);
}
