@keyframes filtered {
    0% {
        transform-origin: right 50%;
        transform: scale3d(0, 1, 1);
    }

    18% {
        transform: scale3d(1, 1, 1);
        transform-origin: right 50%;
    }

    22% {
        transform: scale3d(1, 1, 1);
        transform-origin: left 50%;
    }

    100% {
        transform: scale3d(0, 1, 1);
        transform-origin: left 50%;
    }
}

@keyframes image-scaling {
    0% {
        transform: scale(1);
    }

    18% {
        transform: scale(1.1);
    }

    22% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes arrow-down {
    0% {
        transform: rotate(-90deg) translateX(0);
    }

    100% {
        transform: rotate(-90deg) translateX(5px);
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(19px, 0);
    }
}
