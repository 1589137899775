.product-attributes {
    padding-top: 40px;
    font-family: var(--primary-font);
    text-align: center;
    background-color: var(--gray-light-color);

    @media (--min-tablet) {
        padding-top: 65px;
    }

    .container {
        padding-bottom: 60px;
    }
}

.product-attributes--title {
    margin-bottom: 60px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    letter-spacing: .6px;
    text-align: center;
    color: var(--dark-color);
}

.product-attributes--tabs,
.product-attributes--content {
    &:after {
        content: 'flickity';
        display: none;
    }

    @media (--min-desktop) {
        &:after {
            content: '';
        }
    }

    .flickity-button {
        background: none;

        svg {
            width: 40%;
            height: 40%;
        }
    }
}

.product-attributes--tabs {
    height: 100px;

    @media (--min-desktop) {
        display: flex;
        flex-direction: row;
        height: 50px;
    }
}

.product-tabs--item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (--min-desktop) {
        flex: 1;
        cursor: pointer;

        &:not(:last-child) {
            border-right: 1px solid var(--dark-color);
        }
    }

    &:before {
        position: absolute;
        content: "";
        bottom: 20px;
        left: calc(50% - 50px);
        width: 100px;
        height: 1px;
        background-color: var(--dark-color);
        opacity: 0;
        transition: opacity var(--main-transition);

        @media (--min-desktop) {
            bottom: 0;
        }
    }

    &:after {
        position: absolute;
        content: "";
        bottom: 15px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 1px solid var(--dark-color);
        border-right: 1px solid var(--dark-color);
        background-color: var(--gray-light-color);
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity var(--main-transition);

        @media (--min-desktop) {
            bottom: -5px;
        }
    }

    &.is-selected,
    &:hover {
        &:after {
            opacity: 1;
        }

        &:before {
            opacity: 1;
        }

        .product-tabs--title {
            font-weight: bold;
        }
    }
}

.product-tabs--title {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: .5px;
    text-align: center;
    color: var(--dark-color);
    transition: font-weight var(--main-transition);
}

.product-tabs--subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: .4px;
    text-align: center;
    color: var(--gray-medium-color);
}

.product-attributes--content {
    padding: 48px 20px;
    background-color: var(--light-color);
}

.product-content--item {
    width: 100%;

    @media (--min-desktop) {
        display: none;
    }

    &.is-selected {
        display: block;
    }
}

.product-content--list {
    @media (--min-desktop) {
        display: flex;
    }
}

.product-content--group {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (--min-desktop) {
        flex-direction: column;
        flex: 1;
        justify-content: initial;
        align-items: center;
    }
}

.product-list--element {
    width: calc(50% - 10px);
    margin: 0 10px 20px;
    cursor: pointer;

    @media (--min-tablet) {
        width: auto;
    }
}

.product-list--img {
    margin-bottom: 5px;

    img {
        max-height: 360px;
    }
}

.product-list--text {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: .5px;
    color: var(--dark-color);
}

.product-attributes--modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: color(var(--dark-color) a(.9));

    &.is-visible {
        display: flex;
    }
}

.modal--content {
    display: block;
    max-width: 80%;
    max-height: 90%;
    margin: auto;
    animation: lineDown .6s;
}

.modal--close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: var(--light-color);
    font-size: 40px;
    cursor: pointer;
}

.product-attributes--item {
    display: inline-block;
    width: 135px;
    margin: 0 10px 20px;

    @media (--min-desktop) {
        width: 190px;
        margin: 0 25px 20px;
    }

    &.is-format {
        margin-bottom: 35px;
    }

    &.has-svg {
        width: 100%;
        margin-bottom: 35px;

        @media (--min-tablet) {
            width: 135px;
        }

        @media (--min-desktop) {
            width: 190px;
        }

        svg {
            bottom: 10px;
            font-size: 70px;
        }
    }
}

.product-attributes--media {
    display: block;
    margin-bottom: 10px;
}

.product-attributes--text {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.35;
    letter-spacing: .5px;
}

.product-attributes--subtext {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.7;
    letter-spacing: .4px;
    color: var(--gray-medium-color);
}
