:root {
    --clearfix {
        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    --vcenter {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    --text-center {
        text-align: center;
    }

    --center-content {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
    }

    --container {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-right: auto;
        margin-left: auto;

        @media (--min-tablet) {
            padding-right: 30px;
            padding-left: 30px;
        }

        @media (--min-desktop) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media (--min-desktop-xlarge) {
            max-width: 1200px;
            padding-right: 0;
            padding-left: 0;
        }

        @media (--min-desktop-xxlarge) {
            max-width: 1440px;
        }
    }

    --accelerate {
        transform: translateZ(0);
        backface-visibility: hidden;
        perspective: 1000px;
    }

    --load-animation {
        opacity: 0;
        transform: translateX(-50px);
        transition: all var(--main-transition);

        &.is-loaded {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
