.effects {
    position: relative;
    width: 100%;
    padding: 40px 0;
}

.effects-list {
    @media (--min-tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -50px;
    }
}

.effects-item {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 40px;
    transition: .8s all;

    @media (--min-tablet) {
        flex: 0 1 42%;
        flex: 0 1 calc(50% - 50px);
        margin: 0 25px 80px;
    }

    @media (--min-desktop) {
        flex: 0 1 26%;
        flex: 0 1 calc(33.33% - 100px);
        min-height: 205px;
        margin: 0 50px 80px;
    }
}

.effects-item--image {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: .3s all;

    @media (--min-desktop) {
        position: absolute;
    }

    .effects-item:hover & {
        @media (--min-desktop) {
            height: 130px;
            transition: .4s all;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
    }

    &.is-green:after {
        background: var(--green-color);
    }

    &.is-yellow:after {
        background: var(--yellow-color);
    }

    &.is-purple:after {
        background: var(--purple-color);
    }

    &.is-blue:after {
        background: var(--blue-color);
    }

    &.is-red:after {
        background: var(--red-color);
    }

    &.is-orange:after {
        background: var(--orange-color);
    }

    &.is-dark-blue:after {
        background: var(--dark-blue-color);
    }

    &.is-dark-red:after {
        background: var(--dark-red-color);
    }
}

.effects-item--title {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    color: var(--light-color);
    letter-spacing: 2px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    transition: .4s all .4s;

    .effects-item:hover & {
        opacity: 0;
        transition: .4s all;
    }
}

.effects-item--link {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    font-size: 36px;
    letter-spacing: 4px;
    color: var(--light-color);
    text-align: center;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: .3s all;

    .effects-item:hover & {
        opacity: 1;
        transition: .4s all .4s;
    }

    svg {
        font-size: 22px;
        vertical-align: middle;
    }
}

.effects-item--description {
    width: 100%;
    z-index: -1;
    padding: 15px;
    color: var(--dark-color);
    text-align: center;
    background: var(--gray-light-color);
    transition: .4s .4s;
    transition-property: transform, opacity;

    @media (--min-desktop) {
        position: relative;
        width: 90%;
        padding: 40px 15px 15px;
        margin-top: 100px;
        opacity: 0;
        transform: translateX(80px);
        transition: .3s all;

        .effects-item:hover & {
            opacity: 1;
            transform: translateX(70px);
            transition: .3s all;
        }
    }
}

.effects-item--description-content {
    transition: var(--main-transition);

    @media (--min-desktop) {
        opacity: 0;

        .effects-item:hover & {
            opacity: 1;
            transition: var(--main-transition);
        }
    }
}

.effects-item--description-title {
    display: none;
    padding-bottom: 5px;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;

    @media (--min-desktop) {
        display: block;
    }
}

.effects-item--description-text {
    font-size: 14px;
    letter-spacing: .5px;
    text-align: left;
}
