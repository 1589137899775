.filters {
    background: var(--light-color);
    border: 1px solid var(--gray-medium-color);
    border-width: 1px 0;

    @media (--min-desktop) {
        min-width: 300px;
        margin-top: 0;
        border-top: none;
        border-right: 1px solid var(--gray-medium-color);
        border-bottom: none;
    }

    &.filter-open {
        position: fixed;
        top: 50px;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        padding-bottom: 40px;
        overflow: hidden;
    }

    .container {
        height: 100%;
        padding: 0;
    }

    .catalog-list & {
        border-top: none;
    }
}

.filters--title {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 2px;
    color: var(--dark-color);
    text-transform: uppercase;

    @media (--min-desktop) {
        padding: 15px 20px 15px 40px;
        margin-bottom: 10px;
        font-size: 18px;
        border-bottom: 1px solid var(--gray-medium-color);

        @supports (pointer-events: none) {
            pointer-events: none;
        }
    }

    .filter-open & {
        border-bottom: 1px solid var(--gray-medium-color);
    }

    span {
        display: none;
        text-transform: capitalize;

        .filter-open & {
            display: block;
        }
    }

    svg {
        font-size: 10px;
    }
}

.filters--list {
    display: none;
    max-height: calc(100% - 50px);
    padding: 0 20px;
    overflow: scroll;
    -ms-overflow-style: none;

    @media (--min-desktop) {
        display: block;
        padding: 0;
    }

    .filter-open & {
        display: block;
    }
}

.filters--link {
    position: relative;
    padding: 0;
    margin: 10px 0 25px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.65;
    letter-spacing: 1px;
    color: var(--dark-color);
    background-color: transparent;
    cursor: pointer;
    border: none;
    border-radius: 0;

    @media (--min-desktop) {
        display: inline-block;
        margin: 0 40px 25px 0;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--dark-color);
    }
}

.filters--item {
    overflow: hidden;

    @media (--min-desktop) {
        padding-left: 30px;
    }

    &:nth-child(3),
    &:nth-child(4) {
        padding: 0;
    }

    &:last-child {
        padding-bottom: 25px;

        .filters--header {
            border-top: none;
        }
    }

    &:nth-last-child(2).filter-technical-open {
        border-bottom: 1px solid var(--gray-medium-color);
    }
}

.filters--group {
    margin-bottom: 20px;
    border: 1px solid var(--gray-medium-color);

    @media (--min-desktop) {
        border: none;
    }

    .catalogs & {
        border: none;
    }
}

.filters--group-title {
    display: flex;
    padding: 15px 16px;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    cursor: pointer;

    @media (--min-desktop) {
        justify-content: start;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.08;
        letter-spacing: 3px;
        color: var(--dark-color);
    }

    span {
        margin-left: 10px;
    }

    svg {
        transition: var(--main-transition);
    }

    &.is-open {
        border-bottom: 1px solid var(--gray-medium-color);

        @media (--min-desktop) {
            border: none;
        }

        svg {
            transform: rotate(180deg);
        }
    }

    &.is-selected {
        background-color: var(--gray-light-color);

        @media (--min-desktop) {
            background: none;
        }
    }
}

.filters--group-content {
    display: flex;
    flex-direction: column;
    max-height: 0;
    opacity: 0;
    transition: var(--main-transition);

    .is-open + & {
        max-height: 10000px;
        padding: 10px 0;
        opacity: 1;

        @media (--min-desktop) {
            padding-left: 10px;
        }
    }
}

.filters--input {
    position: relative;
    display: block;
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.65;
    letter-spacing: 1px;
    text-transform: capitalize;
    cursor: pointer;

    @supports (pointer-events: none) {
        pointer-events: none;
    }

    @media (--min-desktop) {
        padding-left: 0;
    }

    .is-open + .filters--group-content & {
        pointer-events: auto;
    }

    &.is-selected {
        background-color: var(--gray-light-color);

        @media (--min-desktop) {
            background: none;
        }
    }

    &.is-disabled {
        color: var(--disabled-color);
        cursor: not-allowed;

        span {
            border-color: var(--disabled-color);
            cursor: not-allowed;
        }

        input {
            cursor: not-allowed;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    span {
        display: none;
        width: 12px;
        height: 12px;
        margin-right: 15px;
        border: solid 1px var(--dark-color);

        @media (--min-desktop) {
            display: inline-block;
        }
    }

    input:checked + span {
        background-color: var(--dark-color);
    }
}

.filters--content {
    position: absolute;
    display: none;
    height: 0;
    opacity: 0;
    padding-top: 20px;
    background: var(--light-color);
    transition: var(--main-transition);
    transform: translateY(-40px);

    @media (--min-desktop) {
        padding: 20px 0 10px 30px;
    }

    @supports (pointer-events: none) {
        pointer-events: none;
    }

    .filter-technical-open & {
        position: relative;
        display: block;
        height: auto;
        opacity: 1;
        transform: translateY(0);
        transition: var(--main-transition);

        @supports (pointer-events: none) {
            pointer-events: auto;
        }
    }

    .catalogs .filter-open & {
        display: block;
        height: auto;
        padding: 20px 0 10px 40px;
        z-index: 0;
        opacity: 1;
        pointer-events: auto;
        transition: var(--main-transition);
        transform: none;
    }

    .catalog-list &,
    .is-logged & {
        @media (--min-desktop) {
            display: block;
            height: auto;
            padding: 20px 0 10px 40px;
            z-index: 0;
            opacity: 1;
            pointer-events: auto;
            transform: none;
            transition: var(--main-transition);
        }
    }
}

.filters--header {
    display: flex;
    align-items: center;
    font-family: var(--primary-font);
    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: var(--dark-color);
    cursor: pointer;

    @media (--min-desktop) {
        padding: 15px 0 15px 40px;
        font-size: 18px;
        border: 1px solid var(--gray-medium-color);
        border-width: 1px 0 1px 0;
    }

    svg {
        transition: var(--main-transition);

        .filter-technical-open & {
            transform: rotate(180deg);
        }
    }

    span {
        margin-left: 10px;
    }
}

.filters--apply {
    display: none;

    .filter-open & {
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: var(--light-color);
        background-color: var(--dark-color);
    }
}

.filters--letter-list {
    display: flex;
    flex-wrap: wrap;
}

.filters--letter-item {
    flex: 0 1 20%;
    margin: 10px 0;
    color: var(--dark-color);

    span {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: var(--dark-color);
            transition: var(--main-transition);
        }
    }

    &:hover {
        span:before {
            width: 100%;
        }
    }

    &.is-selected {
        span:before {
            width: 100%;
        }
    }
}
