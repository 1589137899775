.cta {
    position: relative;
    width: 100%;
    padding: 70px 0 200px;

    @media (--min-desktop) {
        padding: 110px 0 140px;
    }
}

.cta-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cta-icon {
    margin-bottom: 38px;
    font-size: 50px;

    img {
        max-width: 225px;
    }
}

.cta-title {
    margin-bottom: 50px;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: .6px;

    @media (--min-desktop) {
        max-width: 480px;
        font-size: 36px;
    }
}

.cta-button {
    text-transform: uppercase;

    svg {
        vertical-align: middle;
    }
}
