@keyframes fadePulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .25;
    }
}

@keyframes lineDown {
    0% {
        transform: scale(1, 0);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes lineRight {
    0% {
        transform: scale(0, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes underlineHover {
    0% {
        transform: scaleX(1);
        transform-origin: right center;
    }

    48% {
        transform: scaleX(0);
        transform-origin: right center;
    }

    52% {
        transform: scaleX(0);
        transform-origin: left center;
    }

    100% {
        transform: scaleX(1);
        transform-origin: left center;
    }
}
