.fake-content {
    padding-top: 50px;

    @media (--min-desktop) {
        padding-top: 75px;
    }

    img {
        display: block;
        width: 100%;
    }
}
