.login--content {
    padding: 50px 0;

    @media (--min-desktop) {
        padding: 120px 0;
    }
}

.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    width: 100%;
    height: 100vh;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--dark-color);
        opacity: .7;
    }

    &.is-popup-visible {
        display: block;
    }

    @media (--min-desktop) {
        height: 100%;
    }
}

.login-popup--content {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    width: 95%;
    height: 100%;
    transform: translate(-50%, -50%);

    @media (--min-desktop) {
        width: 50%;
    }
}

.login-popup--container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 95%;
    width: 100%;

    @media (--min-desktop) {
        flex-direction: row;
    }
}

.login-popup--left {
    flex: 0 0 50%;
    width: 100%;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        height: auto;
    }
}

.login-popup--right {
    display: none;
    flex: 0 1 50%;
    padding: 30px 30px 40px 40px;
    margin-bottom: 50px;
    overflow: auto;
    background-color: var(--light-color);

    &.is-login.is-step-active {
        display: block;
        min-height: 400px;
    }

    &.is-signin.is-step-active {
        display: block;
    }

    &.is-thankyou.is-step-active {
        display: block;
    }

    &.is-reset.is-step-active {
        display: block;
        min-height: 300px;
    }
}

.login-popup--title {
    max-width: 300px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: .6px;
}

.login-popup--form {
    overflow: auto;
}

.login-popup--text {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: .5px;

    a {
        color: var(--dark-color);
        text-decoration: underline;
    }
}

.login-popup--info {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: .5px;
}

.login-popup--button {
    width: 100%;
    cursor: pointer;
    border: none;

    @media (--min-desktop) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
    }
}

.login-popup--close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: var(--dark-color);
}

.login-bar {
    padding: 15px 0;
    margin-top: 50px;
    font-size: 13px;
    letter-spacing: 1.2px;
    background: var(--gray-light-color);

    @media (--min-desktop) {
        margin-top: 80px;
    }

    .container {
        display: flex;
        justify-content: space-between;
    }
}

.login-bar--exit {
    font-size: 13px;
    color: var(--dark-color);
    letter-spacing: 1.2px;

    &:hover {
        text-decoration: underline;
    }

    svg {
        margin-right: 10px;
        vertical-align: middle;
    }
}
