.professional-filter {
    position: relative;
    width: 100%;
    padding: 15px 20px;
    background-color: var(--gray-light-color);
}

.professional-filter--title {
    margin-bottom: 20px;
    letter-spacing: 1.8px;
}

.professional-filter--list {
    @media (--min-desktop) {
        display: flex;
    }
}

.professional-filter--item {
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;

    @media (--min-tablet) {
        margin-right: 30px;
    }

    span {
        &.is-selected {
            &:after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 100%;
                height: 1px;
                background: var(--black-color);
                transition: width var(--main-transition);
            }
        }
    }
}
