.product-info {
    padding-top: 45px;
    padding-bottom: 35px;

    @media (--min-desktop) {
        display: flex;
        padding-top: 25px;
        padding-bottom: 30px;
    }
}

.product-info--content {
    @media (--min-desktop) {
        flex: 0 0 66.67%;
        padding-top: 85px;
        padding-bottom: 120px;
    }

    @media (--min-desktop-xlarge) {
        flex: 0 0 calc(50% + 200px);
    }

    @media (--min-desktop-xxlarge) {
        flex: 0 0 calc(50% + 240px);
    }
}

.product-info--media {
    height: 110vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        flex: 0 0 33.33%;
        height: auto;
    }

    @media (--min-desktop-xlarge) {
        flex: 0 0 calc(50% - 200px);
    }

    @media (--min-desktop-xxlarge) {
        flex: 0 0 calc(50% - 240px);
    }
}

.product-info--container {
    padding: 0 40px 50px;
    margin-left: auto;

    @media (--min-desktop) {
        padding-right: 70px;
        padding-left: 40px;
    }

    @media (--min-desktop-xlarge) {
        padding-left: 0;
        max-width: 800px;
    }

    @media (--min-desktop-xxlarge) {
        max-width: 960px;
    }
}

.product-info--pretitle {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    @media (--min-tablet) {
        margin-bottom: 10px;
    }
}

.product-info--title {
    margin-bottom: 35px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    letter-spacing: .6px;

    @media (--min-tablet) {
        margin-bottom: 30px;
        font-size: 36px;
        line-height: 1.17;
        letter-spacing: .8px;
    }
}

.product-info--text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.45;
    letter-spacing: .5px;

    @media (--min-tablet) {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.35;
        letter-spacing: .6px;
    }

    p {
        display: inline-block;
        margin-bottom: 15px;

        @media (--min-desktop) {
            column-count: 2;
            column-gap: 70px;
        }
    }
}
