.product-downloads {
    padding-top: 60px;
    padding-bottom: 30px;
    color: var(--light-color);
    text-align: center;
    background-color: var(--dark-color);

    @media (--min-desktop) {
        padding-top: 70px;
        padding-bottom: 55px;
    }
}

.product-downloads--title {
    max-width: 600px;
    margin: 0 auto 50px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: .6px;

    @media (--min-tablet) {
        font-size: 36px;
        line-height: 1.1;
        letter-spacing: .8px;
    }

    @media (--min-desktop) {
        margin-bottom: 60px;
    }
}

.product-downloads--button {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    white-space: nowrap;

    svg {
        font-size: 15px;
        vertical-align: bottom;
    }

    @media (--min-desktop) {
        width: 270px;
        margin: 0 20px 20px;
    }
}
