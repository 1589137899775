.product-head {
    width: 100%;
    overflow: hidden;
}

.product-head--item {
    position: relative;
    width: 100vw;
    min-height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--min-desktop) {
        justify-content: center;
        min-height: 80vh;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-image: linear-gradient(to bottom, color(var(--dark-color) a(70%)), color(var(--dark-color) a(0%)) 60%);

        @media (--min-desktop) {
            background-image: linear-gradient(to right, color(var(--dark-color) a(60%)), color(var(--dark-color) a(0%)) 60%);
        }
    }
}

.product-head--item-title {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: .6px;
    opacity: 0;
    transform: translateX(-20px);

    @media (--min-desktop) {
        font-size: 48px;
        letter-spacing: .8px;
    }

    .is-active & {
        opacity: 1;
        transform: translateX(0);
        transition: opacity .3s var(--main-easing), transform .3s var(--main-easing);
    }
}

.product-head--content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 20vh;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: var(--light-color);
    pointer-events: none;

    @media (--min-desktop) {
        justify-content: center;
        top: 80px;
    }

    .container {
        position: relative;
        padding-top: 200px;
        margin: 0 auto;

        @media (--min-tablet) {
            padding-top: 250px;
        }

        @media (--min-desktop) {
            padding-top: 0;
        }
    }

    .info-window {
        opacity: 0;
        transform: translateY(100%);
        transition: transform var(--main-transition);

        @media (--min-desktop) {
            transform: translateY(-50%) translateX(100%);
        }

        &.is-active {
            opacity: 1;
            transform: translateY(0) translateX(-50%);

            @media (--min-desktop) {
                transform: translateY(-50%);
            }
        }

        &.is-hidding {
            opacity: 0;
            transform: translateY(100%);
            transition: opacity .2s var(--secondary-easing), transform .01s ease .3s;

            @media (--min-desktop) {
                transform: translateY(-50%) translateX(100%);
            }
        }
    }
}

.product-head--caption {
    height: 0;
    max-width: 500px;
    overflow: hidden;
    pointer-events: auto;
    transition: opacity .2s var(--secondary-easing);

    &.is-active {
        height: auto;
        overflow: visible;
    }

    &.is-hidding {
        opacity: 0;
    }
}

.product-head--navigation {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: .4px;
    opacity: 0;
    pointer-events: auto;
    transition: opacity .3s var(--secondary-easing);

    &.is-active {
        opacity: 1;
    }

    span {
        margin-right: 4px;
    }
}

.product-head--arrow {
    padding: 0;
    margin: 0;
    font-size: 25px;
    color: var(--light-color);
    background-color: transparent;
    border: none;
    outline: none;
    cursor: hand;
    cursor: pointer;

    svg {
        display: block;
    }
}

.product-head--anchor {
    width: 180px;
    height: 140px;
    margin: 10px 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: hand;
    cursor: pointer;

    &.is-selected {
        box-shadow: 0 0 10px 2px color(var(--black-color) a(70%));
    }
}

.product-head--footer {
    display: none;
    padding: 25px 60px;
    border-bottom: 1px solid var(--gray-medium-color);

    @media (--min-desktop) {
        display: block;
    }

    .flickity-button {
        background: none;
    }

    .flickity-prev-next-button.previous {
        left: -50px;
    }

    .flickity-prev-next-button.next {
        right: -50px;
    }
}

.product-head--overlay {
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 10;
    width: 100%;
    pointer-events: none;

    @media (--min-tablet) {
        top: 100px;
    }
}

.product-head--breadcrumbs {
    flex: 0 0 100%;
    font-family: var(--primary-font);
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: .4px;
    color: var(--light-color);
    pointer-events: auto;

    @media (--min-tablet) {
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: .4px;
    }

    a {
        color: var(--light-color);
        text-decoration: none;
    }
}

.product-head--back {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: .4px;
    color: var(--light-color);
    pointer-events: auto;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--light-color);
    }

    &:hover:before {
        animation: underlineHover 1s var(--main-easing);
    }
}
