.footer {
    width: 100%;
    padding-top: 20px;
    color: var(--light-color);
    background: var(--dark-color);

    @media (--min-desktop) {
        padding: 140px 0;
    }
}

.footer-content {
    display: flex;
    flex-direction: column;

    @media (--min-desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.footer-intro {
    position: relative;

    @media (--min-desktop) {
        flex: 1 1 25%;
        padding-right: 40px;
    }
}

.footer-icons--list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.footer-icons--item {
    svg {
        width: 1em;
        height: .3em;
    }

    .icon-colorker-logo {
        font-size: 135px;

        @media (--min-desktop-xxxlarge) {
            font-size: 190px;
        }
    }

    .icon-sist-logo-ZYX {
        font-size: 85px;

        @media (--min-desktop-xxxlarge) {
            font-size: 100px;
        }
    }
}

.footer-social--list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @media (--min-desktop) {
        margin-bottom: 0;
    }

    svg {
        color: color(var(--light-color) a(.7));
    }
}

.footer-social--item {
    font-size: 28px;

    a,
    svg {
        display: block;
    }

    &:hover {
        svg {
            color: var(--light-color);
        }
    }
}

.footer-links {
    position: relative;
    margin-bottom: 40px;

    @media (--min-desktop) {
        flex: 1 1 32%;
        padding: 0 40px;
        margin: 0;
    }
}

.footer-links--list {
    @media (--min-desktop) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.footer-links--item {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.4px;
    text-decoration: underline;
    text-transform: uppercase;

    @media (--min-desktop) {
        flex: 1 1 45%;
    }

    a {
        color: var(--light-color);
    }
}

.footer-info {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;

    @media (--min-desktop) {
        flex: 1 1 23%;
        padding: 0 40px;
        margin-bottom: 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 5px;
        height: 1px;
        background: var(--light-color);

        @media (--min-desktop) {
            content: none;
        }
    }
}

.footer-info--list {
    font-size: 14px;
    line-height: 1.43;

    a {
        position: relative;
        color: var(--light-color);

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 0;
            height: 1px;
            background-color: var(--light-color);
            transition: width var(--main-transition);
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }
    }
}

.footer-claim {
    margin-bottom: 10px;
    font-size: 14px;

    @media (--min-desktop) {
        flex: 1 1 20%;
        padding-left: 40px;
        margin-bottom: 0;
    }
}

.footer-claim--name {
    margin-bottom: 20px;

    @media (--min-desktop) {
        margin-bottom: 40px;
        text-align: right;
    }
}

.footer-claim--icon {
    font-size: 40px;
    text-align: center;

    @media (--min-desktop) {
        margin-bottom: 0;
        font-size: 26px;
        text-align: right;
    }

    svg {
        width: 7em;
        height: 1em;
    }
}

.footer-intro,
.footer-links,
.footer-info {
    &:before {
        @media (--min-desktop) {
            content: '';
            position: absolute;
            right: 0;
            bottom: -15px;
            width: 1px;
            height: calc(100% + 20px);
            min-height: 140px;
            background: color(var(--light-color) a(.3));
        }
    }
}
