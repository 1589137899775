.post-header {
    padding: 50px 0;

    @media (--min-desktop) {
        padding: 80px 0;
    }

    .container {
        @media (--min-desktop-xlarge) {
            display: flex;
        }
    }
}

.post-header--intro {
    position: relative;
    width: calc(100% + 40px);
    padding: 30px;
    margin-left: -20px;
    margin-bottom: 40px;

    @media (--min-tablet) {
        width: calc(100% + 60px);
        margin-left: -30px;
    }

    @media (--min-desktop) {
        flex: 0 1 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 65px 65px;
        margin-bottom: 0;
    }

    @media (--min-desktop-xlarge) {
        margin-bottom: 90px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--gray-light-color);

        @media (--min-tablet) {
            width: 80%;
            left: -30px;
        }

        @media (--min-desktop-xlarge) {
            width: 47vw;
            top: 100px;
            right: auto;
            left: -100px;
        }
    }
}

.post--breadcrumbs {
    position: relative;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: .4px;

    @media (--min-desktop-xlarge) {
        max-width: 270px;
    }

    a {
        color: var(--gray-medium-color);
    }
}

.post--title {
    position: relative;
    padding-top: 40px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: .3px;

    @media (--min-tablet) {
        max-width: 80%;
    }

    @media (--min-desktop) {
        font-size: 36px;
    }

    @media (--min-desktop-xlarge) {
        max-width: none;
    }
}

.post-header--content {
    position: relative;
    display: block;
    width: 90%;
    margin: 0 auto;
    color: var(--dark-color);

    @media (--min-tablet) {
        flex: 0 1 55%;
        width: 100%;
        margin: -50px 0 0;
    }

    @media (--min-desktop-xlarge) {
        margin: 0;
    }
}

.post-header--media {
    width: 100%;
    height: 245px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: transform .8s var(--main-easing);

    @media (--min-tablet) {
        height: 420px;
    }

    @media (--min-desktop) {
        height: 460px;
    }
}

.post-header--category {
    position: absolute;
    top: 113px;
    right: -136px;
    width: 245px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transform: rotate(-90deg);

    @media (--min-tablet) {
        top: 288px;
    }

    @media (--min-desktop) {
        top: 220px;
        right: -246px;
        width: 460px;
    }
}

.post-header--info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px;
    letter-spacing: 1.8px;

    @media (--min-desktop) {
        padding: 5px 0 10px 80px;
    }
}
